@import url(https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Roboto:300,300i,400,400i,500,500i,700,700i,900,900i|Oswald:200,300,400,500,600,700&amp;display=swap);
@import url(flaticon.css);
@import url(font-awesome.css);
@import url(animate.css);
@import url(owl.css);
@import url(slick.css);
@import url(jquery.fancybox.min.css);
* {
  margin: 0;
  padding: 0;
  border: none;
  outline: 0;
  font-size: 100%;
}
.sticky-header {
  opacity: 1;
  z-index: 9999;
  visibility: visible;
}

body {
  font-size: 14px;
  color: #777;
  line-height: 28px;
  font-weight: 400;
  background: #000;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
}
a {
  text-decoration: none;
  cursor: pointer;
  color: maroon;
}
a:focus,
a:hover,
a:visited {
  text-decoration: none;
  outline: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  font-weight: 400;
  margin: 0;
  background: 0 0;
  line-height: 1.2em;
  font-family: Raleway, sans-serif;
}

textarea {
  overflow: hidden;
  resize: none;
}
button {
  outline: 0 !important;
  cursor: pointer;
}
.text,
p {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: #555;
  margin: 0;
}
::-webkit-input-placeholder {
  color: inherit;
}
::-moz-input-placeholder {
  color: inherit;
}
::-ms-input-placeholder {
  color: inherit;
}
.auto-container {
  position: static;
  max-width: 1200px;
  padding: 0 0;
  margin: 0 auto;
}
.Clr-chng {
  color: #fff !important;
}
.red-Clr {
  /* color: maroon !important; */
  background: linear-gradient(
    90deg,
    rgba(121, 15, 15, 1) -6%,
    rgba(247, 2, 2, 1) 21%,
    rgba(247, 2, 2, 1) 19%,
    rgba(64, 63, 63, 1) 89%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  /* padding: 0px 10px; */
  min-width: 300px;
  overflow: hidden;
  z-index: 99;
}
.theme-btn {
  display: inline-block;
  text-align: center;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-family: Raleway, sans-serif;
}
.centered {
  text-align: center !important;
}
.btn-style-one {
  position: relative;
  font-size: 16px;
  line-height: 30px;
  color: #fff;
  padding: 15px 40px;
  text-transform: capitalize;
  font-weight: 400;
  background-color: #111;
  overflow: hidden;
}
.btn-style-one .btn-title {
  position: relative;
}
.btn-style-one .icon {
  margin-left: 8px;
  color: maroon;
  font-size: 16px;
}
.btn-style-one:hover {
  color: #111;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}
.btn-style-one:before {
  position: absolute;
  left: -50%;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  background-color: #222;
  opacity: 1;
  -webkit-transform: skewX(-30deg);
  -moz-transform: skewX(-30deg);
  -ms-transform: skewX(-30deg);
  -o-transform: skewX(-30deg);
  transform: skewX(-30deg);
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.btn-style-one:hover:before {
  left: 0;
  background-color: #fff;
  -webkit-transform: skewY(0);
  -moz-transform: skewY(0);
  -ms-transform: skewY(0);
  -o-transform: skewY(0);
  transform: skewY(0);
}
.btn-style-two {
  position: relative;
  font-size: 16px;
  line-height: 30px;
  color: #fff;
  padding: 14px 40px;
  font-weight: 400;
  overflow: hidden;
  background-color: #111;
  border: 1px solid #fff;
}
.btn-style-two span {
  margin-left: 10px;
  color: maroon;
}
.btn-style-two:hover {
  color: maroon;
  border: 1px solid maroon !important;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}
.btn-style-three {
  position: relative;
  font-size: 16px;
  line-height: 30px;
  color: #fff;
  padding: 13px 40px;
  font-weight: 400;
  overflow: hidden;
  background-color: transparent;
  border: 2px solid #fff;
  transition: 0.3s linear;
}
.btn-style-three .icon {
  margin-left: 10px;
  color: maroon;
}
.btn-style-three:hover {
  color: #222;
  border: 2px solid #fff;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}
.btn-style-three .btn-title {
  position: relative;
}
.btn-style-three:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  background-color: #fff;
  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: top right;
  -ms-transform-origin: top right;
  transform-origin: top right;
  -webkit-transition: transform 0.5s cubic-bezier(0.86, 0, 0.07, 1);
  -moz-transition: transform 0.5s cubic-bezier(0.86, 0, 0.07, 1);
  -o-transition: transform 0.5s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.5s cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -moz-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -o-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.btn-style-three:hover:before {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: bottom left;
  -ms-transform-origin: bottom left;
  transform-origin: bottom left;
}
.btn-style-four {
  position: relative;
  font-size: 16px;
  line-height: 30px;
  color: #fff;
  padding: 13px 40px;
  font-weight: 400;
  overflow: hidden;
  background-color: maroon;
  border: 2px solid maroon;
  transition: all 0.3s linear;
}
.btn-style-four:hover {
  color: maroon;
  background-color: #222;
  border: 2px solid #222;
  -webkit-box-shadow: #222 0 0 0 40px inset;
  -moz-box-shadow: #222 0 0 0 40px inset;
  -ms-box-shadow: #222 0 0 0 40px inset;
  -o-box-shadow: #222 0 0 0 40px inset;
  box-shadow: #222 0 0 0 40px inset;
}
.list-style-one {
  position: relative;
  display: block;
  margin-bottom: 30px;
}
.list-style-one li {
  position: relative;
  font-size: 14px;
  line-height: 21px;
  color: #777;
  font-weight: 400;
  padding-left: 35px;
  margin-bottom: 15px;
}
.list-style-one li:before {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 14px;
  line-height: 25px;
  color: maroon;
  font-weight: 900;
  font-family: FontAwesome;
  content: "\f101";
}
.list-style-two {
  position: relative;
  display: block;
  margin-bottom: 30px;
}
.list-style-two li {
  position: relative;
  list-style-type: none;
  font-size: 14px;
  line-height: 20px;
  color: #222;
  font-weight: 500;
  padding-left: 35px;
  margin-bottom: 15px;
  font-family: Rubik, sans-serif;
}
.list-style-two li:before {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 14px;
  line-height: 20px;
  color: maroon;
  font-weight: 900;
  font-family: FontAwesome;
  content: "\f061";
}
.theme_color {
  color: #25262c;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
  padding: 10px 0;
}
/* .preloader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #000;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../images/preloader.png);
} */
img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border: 1px solid maroon;
  color: maroon;
  font-size: 30px;
  line-height: 55px;
  text-align: center;
  z-index: 100;
  cursor: pointer;
  background: #000;
  display: none;
  border-radius: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.scroll-to-top:hover {
  background: maroon !important;
  color: #fff;
}
.main-header {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}

.main-header .header-top {
  position: relative;
}
.main-header .header-top .inner-container {
  position: relative;
}
.main-header .header-top .inner-container:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 1000%;
  background: rgb(121, 15, 15);
  background: linear-gradient(
    90deg,
    rgba(121, 15, 15, 1) 0%,
    rgba(247, 2, 2, 1) 9%,
    rgba(247, 2, 2, 1) -30%,
    rgba(64, 63, 63, 1) 1%
  );
  content: "";
}
.main-header .top-left {
  position: relative;
  float: left;
}
.main-header .top-left .text {
  position: relative;
  padding: 20px 0;
  padding-left: 45px;
  font-size: 14px;
  line-height: 30px;
  color: #fff;
  font-weight: 400;
}
.main-header .top-right {
  position: relative;
  float: right;
  padding: 20px 16px;
}
.main-header .top-right:before {
  position: absolute;
  left: -25px;
  top: 0;
  height: 100%;
  width: 1000%;
  background-color: #222;
  content: "";
}
.social-icon-one {
  position: relative;
  margin-bottom: 0;
}
.social-icon-one li {
  position: relative;
  list-style-type: none;
  float: left;
  margin-left: 25px;
}
.social-icon-one li:first-child {
  margin-left: 0;
}
.social-icon-one li a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: #fff;
  line-height: 30px;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.social-icon-one li a:hover {
  color: maroon;
}
.header-top .change-language {
  position: relative;
  float: right;
  margin-left: 55px;
}
.header-top .change-language .language {
  position: relative;
  padding: 0;
}
.header-top .change-language .language .dropdown-menu {
  left: auto !important;
  right: 0;
  top: 120% !important;
  width: 170px;
  border-radius: 0;
  padding: 0;
  margin-top: 0;
  background-color: #222;
  border: none;
  border-top: 2px solid maroon;
  transform: none !important;
}
.header-top .change-language .language > a {
  position: relative;
  display: block;
  border: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  color: maroon;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}
.header-top .change-language .language > a .flag {
  position: absolute;
  left: 0;
  top: 9px;
  width: 25px;
  height: 13px;
  background-repeat: no-repeat;
  background-position: center;
}
.header-top .change-language .language > a .flag.eng {
  background-image: url();
}
.header-top .change-language .language > a i {
  float: right;
  top: 2px;
  font-size: 13px;
  line-height: 30px;
  color: #999;
  vertical-align: middle;
}
.header-top .change-language .language .dropdown-menu > li {
  padding: 0;
  margin: 0;
  list-style-type: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.header-top .change-language .language .dropdown-menu > li:last-child {
  border-bottom: none;
}
.header-top .change-language .language .dropdown-menu > li > a {
  padding: 8px 15px !important;
  line-height: 24px;
  display: block;
  color: #fff !important;
  text-align: left;
  font-size: 15px;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}
.header-top .change-language .language .dropdown-menu > li > a:hover {
  color: #fff;
  background: maroon;
}
.main-header .top-right .social-icon-one {
  float: left;
}
.main-header .logo-outer {
  position: relative;
  padding: 20px 55px;
}
.main-header .header-upper .upper-right {
  position: relative;
  padding: 20px 0 0;
}
.main-header .info-box {
  position: relative;
  float: left;
  text-align: left;
  min-height: 60px;
  padding-left: 75px;
  margin-left: 50px;
  margin-bottom: 10px;
  padding-top: 3px;
}
.main-header .info-box:first-child {
  margin-left: 0;
}
.main-header .info-box .icon-box {
  position: absolute;
  left: 0;
  top: 0;
  height: 60px;
  width: 60px;
  text-align: left;
}
.main-header .info-box .icon-box span {
  font-size: 50px;
  line-height: 60px;
  color: #000;
}
.main-header .info-box ul {
  position: relative;
  margin: 0;
}
.main-header .info-box li {
  position: relative;
  list-style-type: none;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  font-weight: 400;
}
.main-header .info-box li a {
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.main-header .info-box li a:hover {
  color: maroon;
}
.main-header .info-box strong {
  position: relative;
  display: inline-block;
  font-weight: 700;
  color: #777;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
}
.main-header .header-lower {
  position: relative;
}
.main-header .header-lower .logo-box {
  position: relative;
  float: left;
}
.main-header .header-lower .logo {
  position: relative;
  padding: 27px 0;
}
.main-header .header-lower .logo img {
  position: relative;
  max-width: 100%;
  height: auto;
}
.main-header .header-lower .nav-outer {
  position: relative;
}
.main-header .main-box {
  position: relative;
  left: 0;
  top: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.main-header .main-box:before {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 1000%;
  /* background-color: maroon; */
  background: rgb(121, 15, 15);
  background: linear-gradient(
    90deg,
    rgba(121, 15, 15, 1) 90%,
    rgba(247, 2, 2, 1) 97%,
    rgba(247, 2, 2, 1) 100%,
    rgba(64, 63, 63, 1) 100%
  );
  content: "";
}
.main-menu {
  position: relative;
  float: left;
  /* height: 4.4rem; */
}
.main-menu .navbar-header {
  display: none;
}
.main-menu .navbar-collapse {
  padding: 15px;
  margin-top: 10px;
}
.main-menu .navigation {
  position: relative;
  margin: 0;
}
.main-menu .navigation ul {
  margin: 0;
  list-style-type: none;
}
.main-menu .navigation > li {
  position: relative;
  list-style-type: none;
  float: left;
  padding: 20px 0;
  margin-right: 55px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.main-menu .navigation > li > a {
  position: relative;
  display: block;
  color: #fff;
  text-align: center;
  line-height: 30px;
  opacity: 1;
  font-weight: 700;
  font-size: 14px;
  padding: 0;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.main-menu .navigation > li.dropdown > a {
  padding-right: 6px;
}
.main-menu .navigation > li.dropdown > a:after {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 14px;
  display: block;
  line-height: 30px;
  color: #fff;
  font-weight: 400;
  z-index: 5;
  content: "\f0d7";
  font-family: FontAwesome;
}
.main-menu .navigation > li > ul {
  position: absolute;
  left: 0;
  top: 100%;
  width: 220px;
  margin: 0;
  padding: 20px 20px;
  z-index: 100;
  opacity: 1;
  border-radius: 0 0 15px 15px;
  border-bottom: 2px solid maroon;
  background: rgba(47, 46, 50, 0.9);
  -webkit-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  transform: scaleY(0);
  transform-origin: top;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0 5px 1px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0 5px 1px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0 5px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0 5px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0 5px 1px rgba(0, 0, 0, 0.05);
}
.main-menu .navigation > li > ul.from-right {
  left: auto;
  right: 0;
}
.main-menu .navigation > li > ul > li {
  position: relative;
  width: 100%;
  list-style-type: none;
}
.main-menu .navigation > li > ul > li:last-child {
  border-bottom: none;
}
.main-menu .navigation > li > ul > li > a {
  position: relative;
  display: block;
  padding: 5px 0;
  line-height: 33px;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  text-align: left;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.main-menu .navigation > li > ul > li.dropdown > a:after {
  font-family: FontAwesome;
  content: "\f105";
  position: absolute;
  right: 15px;
  top: 11px;
  width: 10px;
  height: 20px;
  display: block;
  line-height: 20px;
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  font-weight: 900;
  text-align: center;
  z-index: 5;
}
.main-menu .navigation > li > ul > li > ul {
  position: absolute;
  left: 100%;
  top: 100%;
  width: 220px;
  padding: 20px 20px;
  z-index: 100;
  opacity: 1;
  border-radius: 0 0 15px 15px;
  border-bottom: 2px solid maroon;
  background: rgba(47, 46, 50, 0.9);
  margin-top: -50px;
  margin-left: 20px;
  -webkit-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  transform: scaleY(0);
  transform-origin: top;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0 5px 1px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0 5px 1px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0 5px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0 5px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0 5px 1px rgba(0, 0, 0, 0.05);
}
.main-menu .navigation > li > ul > li > ul > li {
  position: relative;
  width: 100%;
}
.main-menu .navigation > li > ul > li > ul > li:last-child {
  border-bottom: none;
}
.main-menu .navigation > li > ul > li > ul > li > a {
  position: relative;
  display: block;
  padding: 5px 0;
  line-height: 24px;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  text-align: left;
  text-transform: capitalize;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}
.main-menu .navigation > li.dropdown:hover > ul {
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -moz-transform: scaleY(1);
  transform: scaleY(1);
  visibility: visible;
  opacity: 1;
  top: 100%;
}
.main-menu .navigation li > ul > li.dropdown:hover > ul {
  opacity: 1;
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -moz-transform: scaleX(1);
  transform: scaleX(1);
  visibility: visible;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}
.main-menu .navbar-collapse > ul li.dropdown .dropdown-btn {
  position: absolute;
  right: 10px;
  top: 8px;
  width: 34px;
  height: 30px;
  border: 1px solid #fff;
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  color: #fff;
  cursor: pointer;
  z-index: 5;
  display: none;
}
.main-header .outer-box {
  position: relative;
  float: right;
}
.main-header .search-box-outer {
  position: relative;
  float: left;
  padding: 17px 0;
  margin-left: 50px;
}
.main-header .search-box-btn {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: #fff;
  line-height: 34px;
  height: 36px;
  width: 36px;
  border: 1px dashed #000;
  border-radius: 50%;
  background-color: transparent;
}
.dropdown-toggle::after {
  display: none;
}
.main-header .search-box-outer .dropdown-menu {
  top: 16px !important;
  right: 0 !important;
  padding: 0;
  width: 280px;
  border-radius: 0;
  border-top: 3px solid maroon;
}
.main-header .search-panel .form-container {
  padding: 25px 20px;
}
.main-header .search-panel .form-group {
  position: relative;
  margin: 0;
}
.main-header .search-panel input[type="password"],
.main-header .search-panel input[type="search"],
.main-header .search-panel input[type="text"],
.main-header .search-panel select {
  display: block;
  width: 100%;
  line-height: 24px;
  padding: 7px 40px 7px 15px;
  height: 40px;
  border: 1px solid #e0e0e0;
  background: #fff;
  font-size: 14px;
}
.main-header .search-panel input:focus,
.main-header .search-panel select:focus {
  border-color: maroon;
}
.main-header .search-panel .search-btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
  text-align: center;
  color: #000;
  font-size: 12px;
  background: 0 0;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.main-header .search-panel .search-btn:hover {
  color: maroon;
}
.main-header .btn-box {
  position: relative;
  float: right;
  margin-left: 40px;
}
.main-header .btn-box .theme-btn {
  float: right;
  padding: 13px 33px;
  line-height: 1.7;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: Roboto, sans-serif;
}
.header-style-two .logo-outer {
  padding: 30px 0;
}
.header-style-two .logo-outer:before {
  position: absolute;
  top: 0;
  right: 100%;
  height: 100%;
  width: 1000%;
  margin-right: -300px;
  background-color: #222;
  content: "";
  z-index: -1;
}
.header-style-two .main-box {
  padding-left: 300px;
}
.header-style-two .header-lower .nav-outer {
  padding-left: 40px;
}
.header-style-two .main-box {
  background-color: #ebebeb;
}
.header-style-two .main-box:before {
  left: 300px;
  right: auto;
}
.header-style-two .header-upper .upper-right {
  padding: 30px 0 0;
}
.rev_slider_wrapper {
  width: 100% !important;
}
.header-style-three {
  position: relative;
  background-image: url(../images/icons/pattern-7.jpg);
  background-repeat: repeat;
}
.header-style-three .auto-container {
  max-width: 1650px;
}
.header-style-three .main-box:before {
  display: none;
}
.header-style-three .logo-outer {
  padding: 0;
}
.header-style-three .header-lower .logo {
  padding: 32px 58px;
}
.header-style-three .header-lower .nav-outer {
  float: right;
  font-family: Raleway, sans-serif;
}
.header-style-three .main-menu .navigation > li.dropdown > a:after,
.header-style-three .main-menu .navigation > li > a {
  color: #fff;
  font-size: 16px;
}
.header-style-three .main-menu .navigation > li {
  padding: 21px 0;
}
.header-style-three .main-menu .navigation > li.current,
.header-style-three .main-menu .navigation > li:hover {
  border-bottom: 0px solid;
  border-image-slice: 1;
  border-width: 3px;
  border-image: linear-gradient(to left, #f70202, #860e0e) 1 0;
}
.setting-nav {
  margin-top: 0 !important;
  padding-left: 5px !important;
}
.header-style-three .outer-box {
  position: relative;
  float: right;
}
.header-style-three .search-box-outer {
  position: relative;
  float: left;
  margin-left: 0;
  padding: 41px 0;
}
.header-style-three .search-box-btn {
  border-color: maroon;
}
.header-style-three .outer-box .info-box {
  position: relative;
  float: left;
  padding: 0;
  margin: 0;
  padding: 51px 0 20px 0;
  margin-left: 50px;
}
.header-style-three .outer-box .info-box a {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  font-weight: 400;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.header-style-three .outer-box .info-box a:hover {
  color: maroon;
}
.header-style-three .outer-box .btn-box {
  position: relative;
  float: right;
  margin-left: 40px;
  padding: 26px 0;
}
.header-style-three .search-box-outer .dropdown-menu {
  top: 57px !important;
}
.sticky-header {
  position: fixed;
  visibility: hidden;
  opacity: 1;
  left: 0;
  top: 0;
  width: 100%;
  padding: 0 0;
  z-index: 99999;
  background: linear-gradient(
    90deg,
    rgba(121, 15, 15, 1) 0%,
    rgba(247, 2, 2, 1) 72%,
    rgba(247, 2, 2, 1) 100%,
    rgba(64, 63, 63, 1) 100%
  );
  border-bottom: 1px solid #000;
}
.navActive {
  opacity: 1;
  z-index: 9999;
  visibility: visible;
  padding: 05px 0;
}
.fixed-header .sticky-header .logo {
  padding: 10px 0;
}
.sticky-header .main-menu .navigation > li {
  margin-left: 43px;
  padding: 0 !important;
  margin-right: 0 !important;
  background-color: transparent;
  list-style-type: none;
}
.sticky-header .main-menu .navigation > li:after {
  display: none;
}
.sticky-header .main-menu .navigation > li > a {
  position: relative;
  display: block;
  padding: 20px 0;
  line-height: 30px;
  font-size: 16px;
  font-weight: 400;
  color: #fff !important;
  border: 0;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.sticky-header .main-menu .navigation > li > .dropdown-setup {
  padding: 0 1px !important;
}
.sticky-header .main-menu .navigation > li.current-menu-item > a,
.sticky-header .main-menu .navigation > li.current > a,
.sticky-header .main-menu .navigation > li:hover > a {
  opacity: 1;
  color: #000;
}
.sticky-header .main-menu .navigation > li.dropdown > a:after,
.sticky-header .main-menu .navigation > li:before {
  display: none;
}
.sticky-header .main-menu .navigation > li.dropdown:hover > ul {
  margin-top: 0;
}
.sticky-header .auto-container {
  max-width: 1750px;
}
.sec-title {
  position: relative;
  margin-bottom: 60px;
}
.sec-title .title {
  position: relative;
  display: block;
  font-size: 20px;
  line-height: 1.2em;
  background: rgb(121, 15, 15);
  background: linear-gradient(
    90deg,
    rgba(121, 15, 15, 1) 0%,
    rgba(247, 2, 2, 1) 72%,
    rgba(247, 2, 2, 1) 100%,
    rgba(64, 63, 63, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: maroon;
  font-weight: 600;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-family: Raleway, sans-serif;
}
.sec-title h2 {
  position: relative;
  display: block;
  font-size: 36px;
  line-height: 1.2em;
  color: #fff;
  font-weight: 700;
}
.sec-title .text {
  font-size: 14px;
  line-height: 28px;
  color: #777;
  margin-top: 40px;
  margin-bottom: 0;
}
.sec-title .devider {
  position: relative;
  display: inline-block;
  margin-top: 33px;
  width: 150px;
  text-align: center;
}
.sec-title .devider:after,
.sec-title .devider:before {
  position: absolute;
  left: 0;
  top: 14px;
  height: 1px;
  width: 52px;
  background-color: #777;
  content: "";
}
.sec-title .devider:after {
  left: auto;
  right: 0;
}
.sec-title .devider span {
  position: relative;
  display: inline-block;
  font-size: 22px;
  line-height: 30px;
  color: #777;
  font-weight: 400;
}
.sec-title.light h2 {
  color: #fff;
}
.page-title {
  position: relative;
  background-image: url(../images/icons/pattern-7.jpg);
  background-repeat: repeat;
  background-position: top;
}
.page-title:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 120px;
  background-color: #fff;
}
.page-title .auto-container {
  max-width: 2550px;
}
section .container{
  max-width: 1750px !important;
}
.news-latters .auto-container {
  max-width: 1750px !important;
}
.page-title .inner-container img {
  height: 23rem;
  width: 100%;
  object-fit: cover;
  /* position: 120px 12px; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.page-title .inner-container {
  position: relative;

  width: 100%;
  border-top: 5px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-image: linear-gradient(to left, #f70202, #860e0e) 1 0 0 0;
}
.page-title .inner-container .outer-box {
  position: absolute;
  top: 0;
  width: 100%;
  margin-top: 120px;
  padding: 50px 0;
  background-color: rgba(0, 0, 0, 0.7);
}
.page-title .inner-container .inner {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
}
.page-title h1 {
  position: relative;
  float: left;
  font-size: 48px;
  color: #fff;
  line-height: 60px;
  font-weight: 700;
  text-transform: capitalize;
}
.courseScreen {
  background-image: url(/public/images/background/82.jpg);
  width: 100%;
  height: 22rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}
.page-title .bread-crumb {
  position: relative;
  float: right;
  padding: 20px 0;
  font-family: Roboto, sans-serif;
  margin: 0;
}
.page-title .bread-crumb li {
  position: relative;
  list-style: none;
  float: left;
  font-size: 18px;
  line-height: 25px;
  color: maroon;
  /* background: rgb(121, 15, 15);
    background: linear-gradient( 90deg, rgba(121, 15, 15, 1) 0%, rgba(247, 2, 2, 1) 72%, rgba(247, 2, 2, 1) 100%, rgba(64, 63, 63, 1) 100% );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
  font-weight: 700;
  text-transform: capitalize;
  cursor: default;
  padding-right: 30px;
  margin-right: 30px;
}
.page-title .bread-crumb li:before {
  position: absolute;
  right: -10px;
  font-size: 16px;
  line-height: 30px;
  background: linear-gradient(
    90deg,
    rgba(121, 15, 15, 1) 0%,
    rgba(247, 2, 2, 1) 72%,
    rgba(247, 2, 2, 1) 100%,
    rgba(64, 63, 63, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  content: "\f178";
  font-family: FontAwesome;
}
.page-title .bread-crumb li:last-child {
  padding-right: 0;
  margin-right: 0;
}
.page-title .bread-crumb li:last-child:before {
  display: none;
}
.page-title .bread-crumb li a {
  position: relative;
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-title .bread-crumb li a:before {
  position: absolute;
  left: 0;
  bottom: -4px;
  height: 2px;
  width: 100%;
  background-color: #fff;
  content: "";
}
.page-title .bread-crumb li a:hover {
  color: maroon;
}
.play-now {
  position: relative;
  z-index: 9;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.play-now:hover {
  background-color: rgba(0, 0, 0, 0.4);
}
.play-now .icon {
  position: relative;
  display: inline-block;
  height: 100px;
  width: 100px;
  text-align: center;
  line-height: 100px;
  background-color: #222;
  color: #fff;
  z-index: 1;
  padding-left: 5px;
  font-size: 30px;
  display: block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.3);
  -moz-box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.3);
  -ms-box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.3);
  -o-box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.3);
  -webkit-transform-origin: center;
  transform-origin: center;
}
.play-now .ripple,
.play-now .ripple:after,
.play-now .ripple:before {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 70px;
  width: 70px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -webkit-animation: ripple 3s infinite;
  -moz-animation: ripple 3s infinite;
  -ms-animation: ripple 3s infinite;
  -o-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
}
.play-now .ripple:before {
  -webkit-animation-delay: 0.9s;
  -moz-animation-delay: 0.9s;
  -ms-animation-delay: 0.9s;
  -o-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: "";
  position: absolute;
}
.play-now .ripple:after {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  -ms-animation-delay: 0.6s;
  -o-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: "";
  position: absolute;
}
@-webkit-keyframes ripple {
  70% {
    box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes ripple {
  70% {
    box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
.main-slider {
  position: relative;
  z-index: 10;
  margin-top: -35px;
}
.main-slider .tp-caption {
  z-index: 5;
}
.main-slider .tp-dottedoverlay {
  background: rgba(0, 0, 0, 0.8);
}
.main-slider:before {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100px;
  width: 100%;
  background-image: url(../images//icons/slider-curve.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  background-color: maroon;
  content: "";
  z-index: 9;
}
.main-btn-new {
  font-size: 15px !important;
  border-radius: 0 !important;
  padding: 17px 36px !important;
  color: #fff !important;
  border: 1px solid #fff !important;
  background-color: #333 !important;
}
.main-btn-new:hover {
  background-color: #fff !important;
  transition: 0.3s ease-in-out !important;
  color: #000 !important;
}
.main-slider h4 {
  font-size: 30px;
  line-height: 1em;
  color: #fff;
  font-weight: 700;
  margin-left: 75px;
}
.main-slider h2 {
  font-size: 48px;
  line-height: 1.2em;
  color: #fff;
  font-weight: 700;
  margin-left: 105px;
}
.main-slider h2 span {
  color: maroon;
}
.main-slider .text {
  position: relative;
  font-size: 18px;
  line-height: 36px;
  color: #fff;
  font-weight: 500;
}
.main-slider .btn-box {
  position: relative;
}
.main-slider .btn-box a {
  margin-right: 30px;
  margin-left: 85px;
  padding: 15px 40px;
}
.main-slider.style-two:before {
  display: none;
}
.main-slider.style-two .tp-dottedoverlay {
  display: none;
}
.main-slider.style-two {
  margin-top: 0;
}
.main-slider.style-two .text {
  font-size: 14px;
  line-height: 24px;
}
.main-slider.style-two h2 {
  font-size: 60px;
  line-height: 84px;
}
.main-slider.style-two h2 span {
  color: maroon;
}
.main-slider.style-two .theme-btn {
  font-weight: 700;
  font-size: 18px;
  padding: 13px 35px;
}
.main-slider .call-to-action {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 99;
}
.main-slider .call-to-action .theme-btn {
  margin: 0;
}
.call-to-action {
  position: relative;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99;
  padding: 40px 0;
}
.call-to-action .content-box {
  position: relative;
  max-width: 970px;
  margin: 0 auto;
}
.call-to-action .title-box {
  position: relative;
  float: left;
}
.call-to-action .title-box h4 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 1.2em;
  color: maroon;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 11px;
}
.call-to-action .title-box .text {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 26px;
  color: #fff;
  font-weight: 400;
}
.call-to-action .title-box .text span {
  color: maroon;
}
.call-to-action .btn-box {
  position: relative;
  float: right;
  padding-top: 10px;
}
.call-to-action .btn-box a {
  position: relative;
  display: inline-block;
  padding: 10px 30px;
  line-height: 20px;
  font-size: 14px;
  color: #222;
  background-color: #fff;
  font-weight: 700;
  font-family: Roboto, sans-serif;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.call-to-action .btn-box a:hover {
  background-color: maroon;
  color: #fff;
}
.welcome-section {
  position: relative;
  padding: 60px 0;
  background-color: #f5f5f5;
}
.welcome-section .title-column {
  position: relative;
}
.welcome-section .title-column .inner-column {
  position: relative;
  text-align: right;
  padding-right: 30px;
  border-right: 1px solid #ddd;
}
.welcome-section .title-column h2 {
  position: relative;
  display: block;
  font-size: 30px;
  line-height: 42px;
  color: maroon;
  font-weight: 700;
  font-family: Roboto, sans-serif;
}
.welcome-section .title-column h2 span {
  color: #222;
  text-transform: uppercase;
}
.welcome-section .text-column {
  position: relative;
}
.welcome-section .text-column .inner-column {
  position: relative;
}
.welcome-section .text-column .text {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 30px;
  color: #777;
  font-weight: 400;
}
.video-section {
  position: relative;
  margin-top: -75px;
  z-index: 99;
}
.video-section .content-box {
  position: relative;
  background-image: url(../images/icons/video-bg.jpg);
  background-repeat: repeat;
  background-position: center;
  background-size: cover;
  border: 5px solid #fff;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.25);
  max-width: 970px;
  min-height: 150px;
  margin: 0 auto;
}
.contact-container {
  background-image: url(../images/background/contact.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  width: 100%;
  padding: 12rem 0;
}
.video-section .content-box .inner-box {
  position: relative;
  padding: 37px 48px;
  padding-right: 220px;
}
.video-section .content-box .title-box {
  position: relative;
  float: left;
}
.styles_scroll-to-top__2A70v:hover {
  background-color: linear-gradient(
    90deg,
    rgba(121, 15, 15, 1) 0%,
    rgba(247, 2, 2, 1) 72%,
    rgba(247, 2, 2, 1) 100%,
    rgba(64, 63, 63, 1) 100%
  ) !important;
}
.styles_scroll-to-top__2A70v {
  background-color: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(96, 7, 7, 1) 47%,
    rgba(255, 0, 0, 1) 100%
  ) !important;
  right: 40px;
  bottom: 40px;
  position: fixed;
  z-index: 2;
  cursor: pointer;
  border-radius: 7px;
  width: 40px;
  height: 40px;
  transition: opacity 1s ease-in-out;
  box-shadow: 0 14px 18px 0 rgb(172 138 177 / 28%);
  border: none;
  outline: none;
}
.video-section .title-box h4 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 1.2em;
  color: maroon;
  font-weight: 700;
  margin-bottom: 11px;
}
.video-section .title-box .text {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 26px;
  color: #fff;
  font-weight: 400;
}
.video-section .title-box .text span {
  color: maroon;
}
.video-section .btn-box {
  position: relative;
  float: right;
  padding-top: 15px;
}
.video-section .btn-box a {
  position: relative;
  display: inline-block;
  padding: 10px 30px;
  line-height: 20px;
  font-size: 14px;
  color: #222;
  background-color: #fff;
  font-weight: 700;
  font-family: Roboto, sans-serif;
}
.video-section .video-box {
  position: absolute;
  right: 0;
  top: 0;
}
.video-section .video-box .image-box {
  position: relative;
}
.ITSetting {
  background: linear-gradient(
    90deg,
    rgba(121, 15, 15, 1) 0%,
    rgba(247, 2, 2, 1) 72%,
    rgba(247, 2, 2, 1) 100%,
    rgba(64, 63, 63, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.video-section .video-box .image {
  position: relative;
  display: block;
  max-width: 145px;
  background-color: maroon;
  margin-bottom: 0;
}
.video-section .video-box .image img {
  opacity: 0.1;
  display: block;
  width: 100%;
  height: auto;
}
.video-section .video-box a {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  line-height: 145px;
  font-size: 34px;
  color: #fff;
}
.video-section-two {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 120px 0 200px;
}
.video-section-two:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  content: "";
  opacity: 0.5;
}
.video-section-two .content-box {
  position: relative;
  text-align: center;
}
.video-section-two .content-box h4 {
  display: block;
  font-size: 30px;
  line-height: 48px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
}
.video-section-two .play-now {
  display: inline-block;
  margin-bottom: 50px;
}
.about-section {
  position: relative;
  padding: 100px 0 10px;
}
.about-section .content-column {
  position: relative;
  margin-bottom: 50px;
}
.about-section .content-column .inner-column {
  position: relative;
}
.about-section .content-column .title {
  position: relative;
  display: block;
  font-size: 20px;
  line-height: 1.2em;
  /* color: maroon; */
  background: linear-gradient(
    90deg,
    rgba(121, 15, 15, 1) 0%,
    rgba(247, 2, 2, 1) 11%,
    rgba(247, 2, 2, 1) 83%,
    rgba(64, 63, 63, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  margin-bottom: 20px;
  font-family: Raleway, sans-serif;
  text-transform: uppercase;
}
.about-section .content-column h2 {
  position: relative;
  display: block;
  font-size: 36px;
  line-height: 1.2em;
  color: #fff;
  font-weight: 700;
  margin-bottom: 24px;
}
.about-section .content-column .message {
  position: relative;
  display: block;
  font-size: 20px;
  line-height: 36px;
  color: #fff;
  font-weight: 700;
  font-family: Raleway, sans-serif;
  margin-bottom: 30px;
}
.about-section .content-column .message span {
  color: maroon;
}
.about-section .content-column .text {
  position: relative;
  font-size: 16px;
  line-height: 36px;
  color: #777;
  font-weight: 400;
  margin-bottom: 10px;
}
.about-section .blockquote {
  position: relative;
  padding-left: 45px;
  /* border-left: 2px solid maroon; */
  border-width: 3px;
  border-style: solid;
  border-image: linear-gradient(to top, red, rgba(0, 0, 0, 0)) 3 100%;

  border-right: none !important;
  margin-bottom: 65px;
}
.about-section .blockquote cite {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 1.2em;
  /* color: maroon; */
  background: linear-gradient(
    90deg,
    rgba(121, 15, 15, 1) 0%,
    rgba(247, 2, 2, 1) 16%,
    rgba(247, 2, 2, 1) 83%,
    rgba(64, 63, 63, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  margin-bottom: 20px;
}
.about-section .blockquote p {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 30px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 0;
}
.about-section .blockquote .icon {
  position: absolute;
  right: 20px;
  top: 0;
  font-size: 18px;
  line-height: 1em;
  color: #777;
}
.about-section .image-column {
  position: relative;
  margin-bottom: 50px;
}
.about-section .image-column .image-box {
  position: relative;
  padding-left: 80px;
  padding-bottom: 80px;
}
.about-section .image-column .image-box:before {
  position: absolute;
  left: 20px;
  bottom: 20px;
  height: 470px;
  width: 465px;
  background-color: #222;
  content: "";
}
.about-section .image-column .image-box:after {
  position: absolute;
  right: -50px;
  top: 130px;
  height: 480px;
  width: 480px;
  background-image: url(../images/icons/pattern-1.png);
  background-repeat: repeat;
  content: "";
  z-index: -1;
}
.about-section .image-column .image-box .image {
  position: relative;
  margin-bottom: 0;
  z-index: 1;
}
.about-section .image-column .image-box .link {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 99;
  height: 100px;
  width: 100px;
  text-align: center;
  line-height: 100px;
  /* background-color: maroon; */
  background: rgb(121, 15, 15);
  background: linear-gradient(
    90deg,
    rgba(121, 15, 15, 1) 0%,
    rgba(247, 2, 2, 1) 72%,
    rgba(247, 2, 2, 1) 100%,
    rgba(64, 63, 63, 1) 100%
  );
  font-size: 22px;
  color: #fff;
  border-radius: 0 50px 0 0;
}
.about-section .image-column .image-box .link span {
  position: relative;
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.about-section .image-column:hover .image-box .link span {
  -webkit-transform: scale(2);
  -moz-transform: scale(2);
  -ms-transform: scale(2);
  -o-transform: scale(2);
  transform: scale(2);
  color: #222;
}
.services-section {
  position: relative;
  padding: 100px 0 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(../images/background/1.jpg);
}
.services-section:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.9;
  content: "";
}
.services-section:after {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 71px;
  width: 100%;
  background-color: #000;
  content: "";
  z-index: 1;
}
.services-carousel {
  position: relative;
  z-index: 9;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.service-block {
  position: relative;
  width: 22.5rem;
}
.service-block .inner-box {
  position: relative;
  overflow: hidden;
}
.service-block .image-box {
  position: relative;
}
.service-block .image-box .image {
  position: relative;
  margin-bottom: 0;
}
.service-block .image-box .image img {
  display: block;
  width: 100%;
}
.service-block .overlay-box {
  position: absolute;
  left: 0;
  top: 0;
  right: 50px;
  height: 100%;
  padding: 0 30px 50px;
  opacity: 0;
  transform: translateX(-50%);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.service-block .inner-box:hover .overlay-box {
  transform: translateX(0);
  opacity: 1;
}
.service-block .overlay-box:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgb(121, 15, 15);
  background: linear-gradient(
    90deg,
    rgba(121, 15, 15, 1) 0%,
    rgba(247, 2, 2, 1) 72%,
    rgba(247, 2, 2, 1) 100%,
    rgba(64, 63, 63, 1) 100%
  );
  /* background-color: maroon; */
  opacity: 0.9;
  content: "";
}
.service-block .overlay-box .icon {
  position: relative;
  height: 70px;
  width: 50px;
  background-color: #222;
  padding-top: 29px;
  text-align: center;
  font-size: 26px;
  color: #fff;
  border-radius: 0 0 30px 30px;
  display: inline-block;
  margin-bottom: 58px;
}
.service-block .overlay-box h5 {
  position: relative;
  display: block;
  font-size: 20px;
  line-height: 1.2em;
  color: #222;
  font-weight: 700;
  margin-bottom: 38px;
}
.service-block .overlay-box h5 a {
  color: #222;
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.service-block .overlay-box h5 a:hover {
  color: #fff;
}
.service-block .overlay-box .text {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 36px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 35px;
}
.service-block .overlay-box .read-more {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  color: #222;
  font-weight: 700;
  font-family: Raleway, sans-serif;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.service-block .overlay-box .read-more:hover {
  color: maroon;
}
.service-block .caption-box {
  position: relative;
  background-color: #000;
  padding: 14px 11px;
  padding-left: 80px;
  border: 1px dashed maroon;
  border-radius: 0 0 30px 30px;
  border-top: 0;
}
.service-block .caption-box h4 {
  position: relative;
  display: block;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  color: #222;
}
.service-block .caption-box h4 a {
  color: #fff;
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.service-block .inner-box:hover .caption-box h4 a {
  color: maroon;
}
.service-block .caption-box .icon {
  position: absolute;
  left: -1px;
  top: 0;
  bottom: -1px;
  width: 50px;
  font-size: 24px;
  /* background-color: maroon; */
  background: rgb(121, 15, 15);
  background: linear-gradient(
    90deg,
    rgba(121, 15, 15, 1) 0%,
    rgba(247, 2, 2, 1) 72%,
    rgba(247, 2, 2, 1) 100%,
    rgba(64, 63, 63, 1) 100%
  );
  border-radius: 0 0 30px 30px;
  text-align: center;
  line-height: 57px;
  color: #222;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.service-block .inner-box:hover .caption-box .icon {
  color: maroon;
  background-color: #222;
}
.services-carousel .owl-nav {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
}
.services-carousel .owl-next,
.services-carousel .owl-prev {
  position: absolute;
  left: -125px;
  top: -70px;
  height: 35px;
  width: 35px;
  text-align: center;
  border: 1px solid #fff;
  color: maroon;
  font-size: 20px;
  line-height: 33px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.services-carousel .owl-next {
  left: auto;
  right: -125px;
}
.services-carousel .owl-next .fa,
.services-carousel .owl-prev .fa {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.services-carousel .owl-next:hover,
.services-carousel .owl-prev:hover {
  background-color: #fff;
}
.services-section-two {
  position: relative;
  padding: 120px 0 90px;
}
.services-section-two:before {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 50%;
  background-image: url(../images/icons/pattern-6.png);
  background-position: center bottom;
  content: "";
  opacity: 0.1;
}
.service-block-two {
  position: relative;
  display: block;
  margin-bottom: 45px;
}
.service-block-two .inner-box {
  position: relative;
  padding: 40px 40px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.service-block-two .icon-box {
  position: relative;
  display: block;
  padding-bottom: 30px;
  margin-bottom: 25px;
}
.service-block-two .icon-box:before {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -30px;
  width: 60px;
  height: 2px;
  background-color: #ccc;
  content: "";
}
.service-block-two .icon-box .icon {
  display: block;
  font-size: 48px;
  color: maroon;
  line-height: 1em;
}
.service-block-two h5 {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 1.2em;
  color: #333;
  font-weight: 700;
  margin-bottom: 20px;
}
.service-block-two h5 a {
  color: #000;
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.service-block-two h5 a:hover {
  color: maroon;
}
.service-block-two .text {
  position: relative;
  color: #000;
  font-size: 14px;
  line-height: 30px;
}
.service-block-three {
  position: relative;
  display: block;
  margin-bottom: 30px;
}
.service-block-three .inner-box {
  position: relative;
  padding: 20px 20px;
  padding-left: 130px;
  text-align: left;
  background-color: #fff;
  border: 1px solid #ddd;
  min-height: 100px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.service-block-three .inner-box:hover {
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.18);
  background-color: maroon;
  border-color: maroon;
}
.service-block-three .icon-box {
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  width: 100px;
  height: 100px;
}
.service-block-three .icon-box:before {
  position: absolute;
  right: 0;
  top: 20px;
  width: 1px;
  height: 60px;
  background-color: #ccc;
  content: "";
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.service-block-three .inner-box:hover .icon-box:before {
  background-color: #fff;
}
.service-block-three .icon-box .icon {
  display: block;
  font-size: 48px;
  color: maroon;
  line-height: 100px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.service-block-three .inner-box:hover .icon-box .icon {
  color: #fff;
}
.service-block-three h5 {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 1.2em;
  color: #333;
  font-weight: 700;
  margin-bottom: 7px;
}
.service-block-three h5 a {
  color: #333;
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.service-block-three .inner-box:hover h5 a {
  color: #fff;
}
.service-block-three .text {
  position: relative;
  color: #333;
  font-size: 14px;
  line-height: 30px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.service-block-three .inner-box:hover .text {
  color: #fff;
}
.service-detail-section {
  position: relative;
  padding: 120px 0 90px;
}
.service-detail {
  position: relative;
}
.service-detail .image-box {
  position: relative;
  margin-bottom: 30px;
}
.service-detail .image-box img {
  position: relative;
  display: block;
  width: 100%;
}
.service-detail .lower-content {
  position: relative;
}
.service-detail .lower-content h2 {
  position: relative;
  display: block;
  font-size: 30px;
  line-height: 1.2em;
  color: #fff;
  font-weight: 700;
  margin-bottom: 25px;
}
.service-detail .lower-content h3 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 1.2em;
  color: #020202;
  font-weight: 700;
  margin-bottom: 20px;
}
.service-detail .lower-content p {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 28px;
  color: #777;
  font-weight: 400;
  margin-bottom: 28px;
}
.service-detail .two-column {
  position: relative;
  margin-top: 40px;
}
.service-detail .two-column .image-column {
  position: relative;
  margin-bottom: 40px;
}
.service-detail .two-column .image {
  position: relative;
}
.service-detail .two-column .image img {
  display: block;
  width: 100%;
  height: auto;
}
.service-detail .two-column .info-column {
  position: relative;
  padding-top: 10px;
}
.service-detail .two-column .list-style-two li {
  list-style-type: none;
  float: left;
  width: 50%;
  margin-bottom: 20px;
}
.service-detail .work-process-tabs .tab-btns .tab-btn {
  margin-right: 10px;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 13px 8px;
  font-weight: 600;
  text-transform: uppercase;
  color: #777;
}
.service-detail .work-process-tabs .tab-btns .tab-btn.active-btn {
  color: #fff;
}
.service-detail .tabs-column {
  margin-bottom: 30px;
}
.service-detail .tabs-column .inner-column {
  padding-right: 20px;
}
.service-detail .image-column .inner-column {
  margin-left: -20px;
}
.service-detail .process-section {
  padding: 80px 0 0;
}
.process-section {
  background-color: #111;
}
.service-detail .process-block .inner-box:before {
  display: none;
}
.service-list-style {
  position: relative;
}

.service-list-style li {
  position: relative;
  display: inline-block;
  margin-right: 30px;
  margin-bottom: 25px;
  font-size: 14px;
  line-height: 30px;
  color: #333;
  font-weight: 600;
  padding: 10px 20px;
  background-color: #e2e2e2;
  min-width: 170px;
  font-family: Raleway, sans-serif;
  text-align: center;
  cursor: default;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.service-list-style li:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}
.service-detail .accordion-box .block:before {
  position: absolute;
  left: 68px;
  top: 20px;
  bottom: 20px;
  width: 1px;
  background-color: #ddd;
  content: "";
  z-index: 2;
}
.service-detail .accordion-box .block.active-block:before {
  background-color: #000;
  bottom: 25px;
}
.service-detail .accordion-box .block .acc-btn {
  padding-left: 100px;
  line-height: 28px;
  background-color: transparent;
}
.service-detail .accordion-box .block .icon {
  left: 30px;
  width: auto;
  right: auto;
  color: #777;
}
.service-detail .accordion-box .block .acc-btn.active {
  background-color: transparent;
  color: maroon;
}
.service-detail .accordion-box .block.active-block .icon:before {
  color: maroon;
}
.service-detail .accordion-box .block .content {
  padding-left: 100px;
  padding-top: 0;
  padding-bottom: 20px;
}
.service-detail .accordion-box .block .content .text {
  font-size: 14px;
  line-height: 27px;
  color: #777;
}
.services--list {
  position: relative;
  margin-bottom: 50px;
}
.services--list li {
  position: relative;
  list-style-type: none;
  overflow: hidden;
  margin-bottom: 10px;
}
.services--list li a {
  position: relative;
  font-size: 14px;
  font-weight: 700;
  color: #777;
  line-height: 28px;
  padding: 15px 25px;
  display: block;
  text-align: right;
  font-family: Roboto, sans-serif;
  border: 1px solid #ddd;
  overflow: hidden;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.services--list li a:before {
  position: absolute;
  content: "\f0d9";
  left: 40px;
  top: 14px;
  font-size: 16px;
  color: #222;
  line-height: 28px;
  font-weight: 400;
  font-family: FontAwesome;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.services--list li.active a:before,
.services--list li:hover a:before {
  color: maroon;
}
.services--list li.active a,
.services--list li:hover a {
  border: 1px solid transparent;
  border-right: 4px solid maroon;
  color: #fff;
}
.services--list li a:after {
  position: absolute;
  right: -20px;
  top: -1px;
  bottom: -1px;
  width: 0;
  content: "";
  background-color: #222;
  z-index: -1;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.services--list li.active a:after,
.services--list li:hover a:after {
  width: 101%;
  transform: skew(-25deg);
}
.services-sidebar .links-box {
  position: relative;
  margin-bottom: 60px;
}
.services-sidebar .links-box h4 {
  display: block;
  font-size: 22px;
  line-height: 1.2em;
  color: #222;
  font-weight: 700;
  margin-bottom: 40px;
}
.services-sidebar .brochure-link {
  position: relative;
  margin-bottom: 10px;
  background-color: #eee;
  transform: skew(-15deg);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.services-sidebar .brochure-link a {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 27px;
  color: #666;
  font-weight: 400;
  padding: 15px 30px;
  padding-left: 35px;
  transform: skew(15deg);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.services-sidebar .brochure-link a i {
  position: relative;
  display: inline-block;
  font-size: 18px;
  line-height: 28px;
  color: #222;
  font-weight: 400;
  margin-right: 25px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.services-sidebar .brochure-link:hover a,
.services-sidebar .brochure-link:hover a i {
  color: #fff;
}
.services-sidebar .brochure-link:hover {
  background-color: maroon;
  color: #fff;
}
.brochure-box {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 45px 40px 45px;
  margin-bottom: 50px;
}
.brochure-box:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #222;
  opacity: 0.9;
  content: "";
}
.brochure-box > span {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: maroon;
  margin-bottom: 10px;
}
.brochure-box h4 {
  position: relative;
  font-size: 24px;
  line-height: 1.2em;
  color: #fff;
  font-weight: 500;
  margin-bottom: 20px;
}
.brochure-box p {
  position: relative;
  font-size: 14px;
  line-height: 28px;
  color: #ccc;
  font-weight: 400;
  margin-bottom: 25px;
}
.brochure-box .theme-btn {
  position: relative;
  padding: 10px 35px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #fff;
  border-radius: 30px;
  border: 1px solid #ddd;
  background-color: transparent;
}
.brochure-box .theme-btn:hover {
  background-color: maroon;
  border-color: maroon;
}
.fun-fact-section {
  position: relative;
  padding: 100px 0 60px;
  background-color: #111;
}
.fun-fact-section .fact-counter {
  position: relative;
}
.fun-fact-section .counter-column {
  position: relative;
  margin-bottom: 40px;
}
.fun-fact-section .count-box {
  position: relative;
  padding-left: 100px;
  padding-top: 25px;
}
.fun-fact-section .count-box:before {
  position: absolute;
  left: 14px;
  top: 0;
  height: 125px;
  width: 30px;
  border: 3px solid maroon;
  content: "";
}
.fun-fact-section .count-box .icon {
  position: absolute;
  left: 0;
  top: 15px;
  font-size: 58px;
  line-height: 1em;
  color: maroon;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.fun-fact-section .count-box:hover .icon {
  color: #000;
  top: 55px;
}
.fun-fact-section .count-box .counter-title {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #777;
  margin-bottom: 10px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.fun-fact-section .count-box .count-text {
  position: relative;
  display: block;
  font-size: 48px;
  line-height: 1.2em;
  color: #777;
  font-weight: 700;
  font-family: Raleway, sans-serif;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.fun-fact-section.style-two {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  padding: 120px 0 80px;
}
.fun-fact-section.style-two:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #222;
  opacity: 0.85;
  content: "";
}
.fun-fact-section.style-two .count-box:before {
  border-color: #fff;
  opacity: 0.2;
}
.fun-fact-section.style-two .count-box .icon,
.fun-fact-section.style-two .count-box:hover .count-text {
  color: #fff;
}
.fun-fact-section.style-two .count-box .count-text,
.fun-fact-section.style-two .count-box:hover .icon {
  color: maroon;
}
.fun-fact-section.style-two .count-box .counter-title {
  color: #fff;
}
.project-section {
  position: relative;
  padding: 100px 0 0;
}
.project-section .layer-image {
  position: absolute;
  left: 50%;
  bottom: 0;
  margin-left: -960px;
  background-color: #000;
  /* background-image: url(../images/background/2.jpg); */
}
.project-section .layer-image img {
  display: block;
  max-width: 100%;
  height: auto;
  opacity: 0.2;
}
.project-section .project-carousel {
  position: relative;
}
.project-block {
  position: relative;
}
.project-block .inner-box {
  position: relative;
  padding-right: 100px;
  padding-bottom: 110px;
}
.project-block .image-box {
  position: relative;
}
.project-block .image-box .image {
  position: relative;
  margin-bottom: 0;
}
.project-block .image-box .image img {
  display: block;
  width: 100%;
  height: auto;
}
.project-block .content-box {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 45px 45px 50px;
  max-width: 400px;
  width: 100%;
  background-color: #000;
  background-image: url(../images/icons/pattern-2.jpg);
  background-repeat: repeat;
  background-position: center;
  opacity: 0;
  -webkit-transform: translateY(-20%);
  -moz-transform: translateY(-20%);
  -ms-transform: translateY(-20%);
  -o-transform: translateY(-20%);
  transform: translateY(-20%);
  -webkit-transition: all 0.7s ease;
  -moz-transition: all 0.7s ease;
  -ms-transition: all 0.7s ease;
  -o-transition: all 0.7s ease;
  transition: all 0.7s ease;
}
.active .project-block .content-box {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 0.4s;
  -moz-transition-delay: 0.4s;
  -ms-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}
.project-block .content-box h4 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 1.2em;
  color: maroon;
  font-weight: 700;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.project-block .content-box h4 a {
  display: inline-block;
  color: maroon;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.project-block .content-box h4 a:hover {
  color: #fff;
}
.project-block .content-box h4:before {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 50px;
  background-color: #fff;
  content: "";
}
.project-block .content-box .text {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 30px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 20px;
}
.project-block .content-box .read-more {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  color: maroon;
  font-weight: 400;
  text-decoration: underline;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.project-block .content-box .read-more:hover {
  color: #fff;
}
.project-carousel .owl-dots {
  position: absolute;
  left: 50%;
  bottom: 37px;
  margin-left: -265px;
}
.project-carousel .owl-dot {
  position: relative;
  display: inline-block;
  height: 10px;
  width: 10px;
  background-color: #b7b7b7;
  border-radius: 50%;
  margin-right: 25px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.project-carousel .owl-dot.active,
.project-carousel .owl-dot:hover {
  background-color: maroon;
}
.project-carousel .owl-nav {
  position: absolute;
  left: 50%;
  margin-left: -75px;
  bottom: 32px;
}
.project-carousel .owl-next,
.project-carousel .owl-prev {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 28px;
  color: maroon;
  font-weight: 400;
  padding: 5px 18px;
  border: 1px solid #bbb;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.project-carousel .owl-next span,
.project-carousel .owl-prev span {
  color: #222;
  float: right;
  margin-left: 10px;
  font-size: 16px;
}
.project-carousel .owl-prev {
  display: none;
}
.project-carousel .owl-next:hover,
.project-carousel .owl-prev:hover {
  color: #fff;
  background-color: maroon;
  border-color: maroon;
}
.project-section .view-more {
  position: absolute;
  left: 50%;
  bottom: 32px;
  margin-left: 55px;
  font-size: 14px;
  line-height: 28px;
  color: #222;
  text-align: center;
  padding: 5px 20px;
  background-color: maroon;
  border: 1px solid maroon;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 9;
}
.project-section .view-more:hover {
  background-color: transparent;
  color: maroon;
}
.projects-feautres {
  position: relative;
  padding: 120px 0 70px;
}
.projects-feautres .text-column {
  position: relative;
  margin-bottom: 50px;
}
.projects-feautres .text-column .inner-column {
  position: relative;
}
.projects-feautres .text-column .sec-title {
  margin-bottom: 50px;
}
.projects-feautres .text-column .text {
  position: relative;
  font-size: 17px;
  line-height: 30px;
  color: #777;
  font-weight: 400;
  margin-bottom: 55px;
}
.projects-feautres .blocks-column {
  position: relative;
  margin-bottom: 50px;
}
.feature-block {
  position: relative;
  margin-bottom: 25px;
}
.feature-block:last-child {
  margin-bottom: 0;
}
.feature-block .inner-box {
  position: relative;

  background: rgb(235, 235, 235);
  background: linear-gradient(
    90deg,
    rgba(235, 235, 235, 1) 68%,
    rgba(255, 255, 255, 1) 100%
  );
  padding: 35px 50px 30px;
  padding-left: 205px;
  min-height: 160px;
}
.feature-block .icon {
  position: absolute;
  left: 50px;
  top: 32px;
  font-size: 84px;
  line-height: 100px;
  color: #0a0a0a;
}
.feature-block h4 {
  display: block;
  font-size: 24px;
  line-height: 1.2em;
  color: #222;
  font-weight: 700;
  margin-bottom: 10px;
}
.feature-block .text {
  display: block;
  font-size: 16px;
  line-height: 30px;
  color: #777;
  font-weight: 400;
}
.feature-block.block-two .inner-box {
  /* background-color: maroon; */
  background: linear-gradient(
    90deg,
    rgba(121, 15, 15, 1) 0%,
    rgba(247, 2, 2, 1) 72%,
    rgba(247, 2, 2, 1) 100%,
    rgba(64, 63, 63, 1) 100%
  );
}
.feature-block.block-two .icon {
  color: #222;
}
.feature-block.block-three .text,
.feature-block.block-three h4,
.feature-block.block-two .text,
.feature-block.block-two h4 {
  color: #fff;
}
.feature-block.block-three .inner-box {
  /* background-color: #222; */
  background: rgb(34, 34, 34);
  background: linear-gradient(
    90deg,
    rgba(34, 34, 34, 1) 54%,
    rgba(68, 68, 68, 1) 94%
  );
}
.feature-block.block-three .icon {
  /* color: maroon; */
  background: linear-gradient(
    90deg,
    rgba(121, 15, 15, 1) 0%,
    rgba(247, 2, 2, 1) 72%,
    rgba(247, 2, 2, 1) 100%,
    rgba(64, 63, 63, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.projects-page-section {
  position: relative;
  padding: 120px 0;
}
.sortable-masonry .filters {
  margin-bottom: 50px;
  text-align: center;
}
.sortable-masonry .filters .filter-tabs {
  position: relative;
  display: inline-block;
  margin: 0;
  list-style-type: none;
}
.sortable-masonry .filters li {
  position: relative;
  display: inline-block;
  line-height: 20px;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  color: #fff;
  font-weight: 400;
  margin: 0 8px 0;
  border: 1px solid transparent;
  border-radius: 5px;
  font-family: Roboto, sans-serif;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.sortable-masonry .filters li:last-child {
  margin-right: 0;
}
.sortable-masonry .filters .filter.active,
.sortable-masonry .filters .filter:hover {
  color: maroon;
  border: 1px solid #ccc;
}
.project-block-two {
  position: relative;
  margin-bottom: 30px;
}
.project-block-two .inner-box {
  position: relative;
}
.project-block-two .image-box {
  position: relative;
}
.project-block-two .image-box .image {
  position: relative;
  margin-bottom: 0;
}
.project-block-two .image-box .image img {
  display: block;
  width: 100%;
  height: auto;
}
.project-block-two .overlay-box {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(34, 34, 34, 0.9);
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -ms-transform: scale(0.7);
  -o-transform: scale(0.7);
  transform: scale(0.7);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.project-block-two .inner-box:hover .overlay-box {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.project-block-two .upper-box {
  position: absolute;
  right: 0;
  top: 28px;
  text-align: right;
  padding: 0 30px;
}
.project-block-two .upper-box h4 {
  position: relative;
  display: block;
  font-size: 20px;
  line-height: 1.2em;
  color: #fff;
  font-weight: 700;
  margin-bottom: 5px;
}
.project-block-two .upper-box h4 a {
  color: #fff;
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.project-block-two .upper-box h4 a:hover {
  color: maroon;
}
.project-block-two .upper-box .category {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 24px;
  color: maroon;
}
.project-block-two .link {
  position: absolute;
  left: 30px;
  bottom: 33px;
  font-size: 16px;
  line-height: 26px;
  color: #fff;
  font-weight: 400;
  padding-bottom: 7px;
}
.project-block-two .link:before {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 60%;
  background-color: maroon;
  content: "";
}
.projects-page-section .btn-box {
  position: relative;
  margin-top: 50px;
}
.project-detail {
  position: relative;
  padding: 120px 0;
}
.project-detail .inner-container {
  position: relative;
  max-width: 970px;
  margin: 0 auto;
}
.project-detail .upper-box {
  position: relative;
  border: 3px solid #ddd;
  padding: 23px 0 0 45px;
  margin-top: 40px;
}
.project-detail .project-info {
  position: relative;
  margin: 0;
  list-style-type: none;
}
.project-detail .project-info li {
  position: relative;
  float: left;
  font-size: 14px;
  line-height: 28px;
  color: #777;
  font-weight: 400;
  margin-bottom: 23px;
  padding-left: 40px;
  width: 33.3333%;
  padding-right: 20px;
}
.project-detail .project-info li a {
  color: #777;
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.project-detail .project-info li a:hover {
  color: #fa0;
}
.project-detail .project-info li .icon {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 20px;
  line-height: 28px;
  color: maroon;
}
.project-detail .project-info li strong {
  color: #fff;
  font-family: Roboto, sans-serif;
}
.project-detail .title-box {
  position: relative;
}
.project-detail .title-box h2 {
  display: block;
  font-size: 30px;
  line-height: 1.2em;
  color: #fff;
  font-weight: 700;
  margin-bottom: 25px;
}
.project-detail .title-box .text {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 24px;
  color: #777;
  font-weight: 400;
  margin-bottom: 35px;
}
.project-detail .single-item-carousel .image-box {
  position: relative;
}
.project-detail .single-item-carousel .image {
  position: relative;
  margin-bottom: 0;
}
.project-detail .single-item-carousel .image img {
  display: block;
  width: 100%;
  height: 200px;
}
.project-detail .single-item-carousel .owl-nav {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  margin-top: -25px;
}
.project-detail .single-item-carousel .owl-next,
.project-detail .single-item-carousel .owl-prev {
  position: absolute;
  left: -100px;
  height: 50px;
  width: 50px;
  background-color: #33373f;
  border-radius: 50%;
  text-align: center;
  font-size: 24px;
  line-height: 50px;
  color: #fff;
  font-weight: 400;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.project-detail .single-item-carousel .owl-next {
  left: auto;
  right: -100px;
}
.project-detail .single-item-carousel .owl-next:hover,
.project-detail .single-item-carousel .owl-prev:hover {
  background-color: maroon;
  color: #fff;
}
.project-detail .lower-content {
  position: relative;
  padding-top: 35px;
}
.project-detail .lower-content h3 {
  font-size: 24px;
  line-height: 1.2em;
  color: #fff;
  font-weight: 700;
  margin-bottom: 20px;
}
.project-detail .lower-content p {
  font-size: 14px;
  line-height: 27px;
  color: #999;
  margin-bottom: 30px;
}
.project-detail .two-column {
  position: relative;
  margin-top: 40px;
}
.project-detail .two-column .column {
  position: relative;
  margin-bottom: 40px;
}
.project-detail .video-box {
  position: relative;
  margin-bottom: 50px;
}
.video-box {
  position: relative;
}
.video-box .image {
  position: relative;
  margin-bottom: 0;
}
.video-box .image img {
  display: block;
  width: 100%;
}
.video-box .link {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.video-box .link .icon {
  position: absolute;
  display: inline-block;
  left: 50%;
  top: 50%;
  height: 60px;
  width: 60px;
  text-align: center;
  line-height: 60px;
  margin-left: -30px;
  margin-top: -30px;
  color: maroon;
  font-size: 24px;
  border-radius: 50%;
  background-color: #fff;
  padding-left: 5px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.video-box .link:hover .icon {
  background-color: maroon;
  color: #fff;
}
.project-detail .project-files {
  position: relative;
  padding: 35px 35px 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.19);
  border-radius: 20px;
}
.project-detail .project-files h4 {
  display: block;
  font-size: 18px;
  line-height: 1.2em;
  color: #fff;
  font-weight: 700;
  margin-bottom: 30px;
}
.project-detail .file-list {
  position: relative;
  list-style-type: none;
  margin: 0;
}
.project-detail .file-list li {
  position: relative;
  float: left;
  width: 50%;
  font-size: 14px;
  line-height: 20px;
  color: #777;
  font-weight: 400;
  margin-bottom: 20px;
}
.project-detail .file-list li a {
  color: #777;
  padding-left: 40px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.project-detail .file-list li .icon {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
  color: maroon;
}
.project-detail .file-list li a:hover {
  color: maroon;
}
.load-more-option {
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
.load-more-option ul {
  position: relative;
  margin: 0;
  list-style-type: none;
}
.load-more-option li.next a,
.load-more-option li.prev a {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 30px;
  width: 140px;
  padding: 10px 20px;
  text-align: center;
  color: #fff;
  background-color: #222;
  text-transform: uppercase;
  border-radius: 0 30px 30px 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.load-more-option li.next a {
  border-radius: 30px 0 0 30px;
}
.load-more-option li.next a:hover,
.load-more-option li.prev a:hover {
  background-color: maroon;
}
.load-more-option .load-more {
  position: relative;
  display: inline-block;
  font-size: 30px;
}
.load-more-option .load-more a {
  font-size: 30px;
  line-height: 50px;
  color: maroon;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.load-more-option .load-more a:hover {
  color: #000;
}
.team-section {
  position: relative;
  padding: 100px 0;
}
.team-section:before {
  position: absolute;
  left: 50%;
  top: 0;
  height: 430px;
  width: 100%;
  background-color: #111;
  content: "";
  -webkit-transform: translateX(-375px);
  -moz-transform: translateX(-375px);
  -ms-transform: translateX(-375px);
  -o-transform: translateX(-375px);
  transform: translateX(-375px);
}
.team-section .team-carousel {
  position: relative;
  padding-bottom: 110px;
}
.team-block {
  position: relative;
}
.team-block .inner-box {
  position: relative;
}
.team-block .image-box {
  position: relative;
  overflow: hidden;
}
.red-Hover:hover {
  color: maroon !important;
}
.team-block .image-box .image {
  position: relative;
  margin-bottom: 0;
  border-radius: 0 0 20px 20px;
  overflow: hidden;
  height: 326px;
}
.team-block .image-box .image img {
  display: block;
  width: 100%;
}
.team-block .social-links {
  position: absolute;
  right: -100%;
  bottom: 0;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 0 0 0 20px;
  padding: 0 15px;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.team-block .inner-box:hover .social-links {
  opacity: 1;
  right: 0;
}
.team-block .social-links li {
  position: relative;
  list-style-type: none;
  float: left;
  margin-left: 5px;
}
.team-block .social-links li:first-child {
  margin-left: 0;
}
.team-block .social-links li a {
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  padding: 15px 0;
  width: 40px;
  text-align: center;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.team-block .social-links li a:hover {
  background-color: maroon;
}
.team-block .caption-box {
  position: relative;
  text-align: center;
  padding: 33px 0 0;
}
.team-block .caption-box .name {
  display: block;
  font-size: 20px;
  line-height: 1.2em;
  color: #222;
  font-weight: 700;
  margin-bottom: 15px;
}
.team-block .caption-box .name a {
  color: #fff;
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.team-block .caption-box .name a:hover {
  color: maroon;
}
.team-block .caption-box .description {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 1.2em;
  color: #777;
  font-weight: 400;
}
.team-carousel .owl-nav {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
}
.team-carousel .owl-next,
.team-carousel .owl-prev {
  position: relative;
  display: inline-block;
  height: 35px;
  width: 35px;
  text-align: center;
  border: 1px solid #999;
  color: maroon;
  font-size: 20px;
  line-height: 33px;
  margin: 0 12px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.team-carousel .owl-next .fa,
.team-carousel .owl-prev .fa {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.team-carousel .owl-next:hover,
.team-carousel .owl-prev:hover {
  color: #404040;
  border-color: maroon;
}
.team-section.alternate {
  padding: 120px 0 90px;
}
.team-section.alternate .team-block {
  position: relative;
  margin-bottom: 30px;
}
.team-detail {
  position: relative;
  padding: 120px 0 70px;
}
.team-detail .image-column {
  position: relative;
  margin-bottom: 50px;
}
.team-detail .image-column .image-box {
  position: relative;
  padding-top: 15px;
}
.team-detail .image-column .image {
  position: relative;
  margin-bottom: 0;
}
.team-detail .image-column .image img {
  display: block;
  width: 100%;
  height: auto;
}
.team-detail .content-column {
  position: relative;
  margin-bottom: 50px;
}
.team-detail .content-column .inner-column {
  position: relative;
}
.team-detail .content-column .info-box {
  position: relative;
  display: block;
  margin-bottom: 40px;
}
.team-detail .content-column .name {
  display: block;
  font-size: 30px;
  line-height: 1.2em;
  color: #fff;
  font-weight: 700;
  margin-bottom: 5px;
}
.team-detail .content-column .designation {
  position: relative;
  font-size: 16px;
  line-height: 1.2em;
  color: maroon;
  font-weight: 400;
}
.team-detail .content-column .text-box {
  position: relative;
  margin-bottom: 70px;
}
.team-detail .content-column p {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #777;
  margin-bottom: 28px;
  font-family: Roboto, sans-serif;
}
.team-detail .contact-list {
  position: relative;
  margin: 0;
}
.team-detail .contact-list li {
  position: relative;
  list-style-type: none;
  display: block;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  color: #888;
  padding-left: 45px;
  margin-bottom: 10px;
}
.team-detail .contact-list li a {
  color: #999;
}
.team-detail .contact-list li .icon {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 16px;
  line-height: 26px;
  color: maroon;
}
.team-detail .social-links {
  position: relative;
  margin-top: 36px;
  margin-bottom: 0;
  float: right;
}
.team-detail .social-links li {
  position: relative;
  list-style-type: none;
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  color: #222;
  font-weight: 400;
  margin-left: 15px;
}
.team-detail .social-links li a {
  color: #888;
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.team-detail .social-links li.title {
  font-size: 18px;
  line-height: 26px;
  color: maroon;
  font-weight: 700;
  margin-right: 15px;
  margin-left: 0;
  font-family: Raleway, sans-serif;
}
.team-detail .social-links li a:hover {
  color: maroon;
}
.testimonial-section {
  position: relative;
}
.testimonial-section:before {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100px;
  background-repeat: repeat-y;
  background-position: left top;
  content: "";
}
.testimonial-section .layer-image {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 40.4%;
  background-image: url(../images/background/2.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.testimonial-section .testimonial-outer {
  position: relative;
  background-color: #111;
  max-width: 770px;
  float: right;
  width: 100%;
  padding: 40px 20px;
  /* padding-left: 100px; */
}
.testimonial-section .layer-image:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: maroon;
  opacity: 0;
  content: "";
}
.testimonial-block {
  position: relative;
}
.testimonial-block .inner-box {
  position: relative;
  padding-left: 50px;
}
.testimonial-block .inner-box:before {
  position: absolute;
  left: 14px;
  top: 0;
  height: 90px;
  width: 2px;
  background-color: #e1e1e1;
  content: "";
}
.testimonial-block .icon {
  position: absolute;
  left: 0;
  top: 15px;
  height: 30px;
  width: 30px;
  background-color: #e1e1e1;
  font-size: 16px;
  line-height: 30px;
  color: #222;
  text-align: center;
}
.testimonial-block .text {
  position: relative;
  font-size: 16px;
  line-height: 30px;
  color: #777;
  font-weight: 400;
  margin-bottom: 85px;
}
.testimonial-block .info-box {
  position: relative;
  padding-left: 180px;
  padding-top: 60px;
  min-height: 130px;
}
.testimonial-block .info-box .thumb {
  position: absolute;
  left: 0;
  top: 0;
  height: 130px;
  width: 130px;
}
.testimonial-block .info-box .thumb img {
  display: block;
  width: 75%;
}
.testimonial-block .info-box .name {
  position: relative;
  display: block;
  padding-top: 15px;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 5px;
}
.testimonial-block .info-box .name:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 1px;
  width: 50px;
  background-color: #888;
  content: "";
}
.testimonial-block .info-box .designation {
  display: block;
  font-size: 16px;
  line-height: 25px;
  color: maroon;
  font-weight: 700;
}
.testimonial-block .info-box .designation span {
  color: #222;
  margin-right: 5px;
}
.testimonial-carousel .owl-nav {
  position: absolute;
  bottom: 140px;
  right: 0;
}
.testimonial-carousel .owl-next,
.testimonial-carousel .owl-prev {
  position: relative;
  display: inline-block;
  height: 35px;
  width: 35px;
  text-align: center;
  border: 1px solid #fff;
  color: maroon;
  font-size: 20px;
  line-height: 33px;
  margin: 0 12px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.testimonial-carousel .owl-next .fa,
.testimonial-carousel .owl-prev .fa {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.testimonial-carousel .owl-next:hover,
.testimonial-carousel .owl-prev:hover {
  color: #404040;
  border-color: maroon;
}
.testimonial-section .form-column {
  position: relative;
}
.testimonial-section .form-column .inner-column {
  position: relative;
  padding-right: 100px;
  padding-top: 120px;
  margin-top: -90px;
}
.testimonial-section .form-column .inner-column:before {
  position: absolute;
  right: 0;
  top: 0;
  bottom: -126px;
  width: 1000%;
  background-color: maroon;
  content: "";
}
.testimonial-section .form-column .sec-title .devider span,
.testimonial-section .form-column .sec-title .title {
  color: #fff;
}
.testimonial-section .form-column .sec-title .devider:after,
.testimonial-section .form-column .sec-title .devider:before {
  background-color: #fff;
}
.testimonial-section .request-form .form-group input[type="email"],
.testimonial-section .request-form .form-group input[type="text"],
.testimonial-section .request-form .form-group input[type="url"],
.testimonial-section .request-form .form-group select,
.testimonial-section .request-form .form-group textarea {
  height: 60px;
  padding: 20px 28px;
}
.testimonial-section .request-form .form-group textarea {
  height: 120px;
  margin-bottom: 10px;
}
.testimonial-section .request-form .form-group button {
  border: 0;
  padding: 20px 45px;
}
.testimonial-section.style-two .testimonial-column .inner-column {
  padding: 120px 0;
  padding-left: 70px;
}
.testimonial-section.style-two .testimonial-block .inner-box {
  padding-left: 80px;
}
.testimonial-section.style-two .testimonial-block .text {
  font-size: 16px;
  line-height: 36px;
  color: #777;
  margin-bottom: 45px;
}
.testimonial-section.style-two .testimonial-block .info-box {
  padding-left: 160px;
}
.testimonial-section.style-two .testimonial-carousel {
  padding-bottom: 45px;
  border-bottom: 2px solid #e1e1e1;
}
.testimonial-section.style-two .testimonial-carousel .owl-nav {
  bottom: -18px;
  background-color: #fff;
}
.testimonial-section.style-two:before {
  display: none;
}
.fluid-section-one {
  position: relative;
}
.fluid-section-one .outer-container {
  position: relative;
  min-height: 350px;
}
.fluid-section-one .image-column {
  position: absolute;
  float: left;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
}
.fluid-section-one .image-column .image-box {
  position: relative;
  display: none;
}
.fluid-section-one .image-column .image-box img {
  position: relative;
  display: block;
  width: 100%;
}
.fluid-section-one .content-column {
  position: relative;
  float: right;
  width: 50%;
}
.fluid-section-one .content-column:before {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: url(../images/icons/pattern-6.png);
  background-position: center bottom;
  content: "";
  opacity: 0.1;
}
.fluid-section-one .content-column .inner-box {
  position: relative;
  max-width: 720px;
  padding: 100px 15px 50px 115px;
}
.fluid-section-one .content-column .inner-box .sec-title {
  margin-bottom: 45px;
}
.fluid-section-one .content-column .inner-box .text {
  position: relative;
  margin-bottom: 50px;
}
.fluid-section-one .content-column .inner-box .text p {
  position: relative;
  font-size: 14px;
  line-height: 27px;
  color: #777;
  margin-bottom: 27px;
}
.fluid-section-one .content-column .inner-box .text p:last-child {
  margin-bottom: 0;
}
.fluid-section-one .content-column .inner-box .choose-info {
  position: relative;
  margin: 0;
}
.fluid-section-one .content-column .inner-box .choose-info li {
  position: relative;
  float: left;
  width: 50%;
  list-style-type: none;
  padding-left: 70px;
  margin-bottom: 30px;
  min-height: 50px;
  text-transform: capitalize;
  cursor: default;
}
.fluid-section-one .content-column .inner-box .choose-info li h5 {
  position: relative;
  display: block;
  font-size: 18px;
  font-weight: 700;
  color: #222;
  margin-bottom: 8px;
}
.fluid-section-one .content-column .inner-box .choose-info li h5 a {
  color: #222;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.fluid-section-one .content-column .inner-box .choose-info li .icon {
  position: absolute;
  left: 0;
  top: 0;
  color: maroon;
  line-height: 1em;
  font-size: 48px;
  font-weight: 400;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.fluid-section-one .content-column .inner-box .choose-info li:hover .icon,
.fluid-section-one .content-column .inner-box .choose-info li:hover a {
  color: maroon;
}
.fluid-section-one.alternate .content-column:before {
  display: none;
}
.process-section {
  position: relative;
  padding: 58px 0 70px;
}
.process-block {
  position: relative;
  margin-bottom: 50px;
}
.process-block .inner-box {
  position: relative;
  text-align: center;
}
.process-block .inner-box:before {
  position: absolute;
  right: -60px;
  top: 200px;
  height: 45px;
  width: 102px;
  background-image: url(../images/icons/icon-twist-arrow.png);
  content: "";
}
.process-block:nth-child(3n + 3) .inner-box:before {
  display: none;
}
.process-block .count {
  position: relative;
  display: inline-block;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  font-size: 18px;
  line-height: 60px;
  color: #222;
  font-weight: 700;
  background-color: #ebebeb;
  margin-bottom: 60px;
}
.process-block .count:before {
  position: absolute;
  top: 100%;
  left: 29px;
  height: 50px;
  width: 1px;
  /* background-color: maroon; */
  background: linear-gradient(
    260deg,
    rgba(121, 15, 15, 1) 0%,
    rgba(247, 2, 2, 1) 72%,
    rgba(247, 2, 2, 1) 100%,
    rgba(64, 63, 63, 1) 100%
  );
  -webkit-text-fill-color: transparent;
  content: "";
}
.process-block .icon-box {
  position: relative;
  display: block;
  height: 150px;
  width: 150px;
  text-align: center;
  line-height: 150px;
  font-size: 65px;
  margin: 0 auto 25px;
  border-radius: 50%;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.13);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.process-block .inner-box:hover .icon-box {
  background-color: #222;
  box-shadow: none;
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
.process-block .icon-box .icon {
  position: relative;
  display: inline-block;
  /* color: maroon; */
  background: linear-gradient(
    90deg,
    rgba(121, 15, 15, 1) 0%,
    rgba(247, 2, 2, 1) 37%,
    rgba(247, 2, 2, 1) 100%,
    rgba(64, 63, 63, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.process-block .inner-box:hover .icon-box .icon {
  color: #fff;
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.process-block h4 {
  display: block;
  font-size: 24px;
  line-height: 1.2em;
  color: #fff;
  font-weight: 700;
  margin-bottom: 20px;
}
.process-block h4 a {
  color: #777;
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.process-block h4 a:hover {
  color: maroon;
}
.process-block .title {
  display: block;
  font-size: 18px;
  /* color: maroon; */
  background: linear-gradient(
    90deg,
    rgba(121, 15, 15, 1) 0%,
    rgba(247, 2, 2, 1) 72%,
    rgba(247, 2, 2, 1) 100%,
    rgba(64, 63, 63, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-family: Raleway, sans-serif;
}
.process-section.alternate .process-block .inner-box:hover .icon-box {
  background-color: #222;
}
.process-section.alternate .process-block .inner-box:hover .icon-box .icon {
  color: #222;
}
.faqs-section {
  position: relative;
  padding: 115px 0 70px;
}
.faqs-section .faq-column {
  position: relative;
}
.faqs-section .faq-column .inner-column {
  position: relative;
  padding-top: 20px;
}
.accordion-box {
  position: relative;
  margin: 0;
}
.accordion-box .block {
  position: relative;
  margin-bottom: 20px;
  list-style-type: none;
  border: 1px solid #858585;
}
.accordion-box .block .acc-btn {
  position: relative;
  font-size: 18px;
  line-height: 30px;
  color: #fff;
  font-weight: 400;
  cursor: pointer;
  padding: 15px 30px;
  padding-right: 55px;
  background-color: #000;
  font-family: Raleway, sans-serif;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.accordion-box .block .acc-btn.active {
  color: maroon;
  background-color: transparent;
}
.accordion-box .block .icon {
  position: absolute;
  right: 15px;
  top: 13px;
  height: 35px;
  width: 35px;
  font-size: 20px;
  line-height: 35px;
  color: maroon;
  text-align: center;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.accordion-box .block .acc-btn.active .icon {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  color: #222;
}
.accordion-box .block .acc-content {
  position: relative;
  display: none;
}
.accordion-box .block .content {
  position: relative;
  padding: 15px 30px 30px;
}
.accordion-box .block .acc-content.current {
  display: block;
}
.accordion-box .block .content .text {
  display: block;
  font-size: 14px;
  line-height: 30px;
  color: #fff;
  margin-bottom: 0;
}
.faqs-section .image-column {
  position: relative;
}
.faqs-section .image-column .image-box {
  position: relative;
  margin-bottom: 50px;
}
.faqs-section .image-column .image {
  margin-bottom: 0;
  margin-left: -10px;
}
.faqs-section .image-column .image img {
  max-width: 100%;
  height: auto;
}
.faqs-section.style-two {
  padding: 0;
}
.faqs-section.style-two .faq-column .inner-column {
  padding: 120px 0 90px;
}
.faqs-section.style-two .image-column {
  position: relative;
}
.faqs-section.style-two .image-column .image-box {
  position: absolute;
  right: 0;
  top: 340;
}
.faqs-section.style-two .image-column .image {
  margin-bottom: 0;
}
.faqs-section.style-two .image-column .image img {
  max-width: 100%;
  height: auto;
}
.faq-form-section {
  position: relative;
  padding: 120px 0 90px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.faq-form-section:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: maroon;
  opacity: 0;
  content: "";
}
.faq-form-section .inner-container {
  position: relative;
  max-width: 970px;
  margin: 0 auto;
}
.faq-form-section .sec-title {
  position: relative;
}
.faq-form-section .sec-title .devider:after,
.faq-form-section .sec-title .devider:before {
  background-color: #fff;
}
.faq-form-section .sec-title span {
  color: #fff;
}
.faq-form-section .sec-title h2 {
  color: #fff;
}
.faq-form-section .faq-form {
  position: relative;
}
.faq-form .form-group {
  position: relative;
  margin-bottom: 30px;
}
.faq-form .form-group input[type="email"],
.faq-form .form-group input[type="text"],
.faq-form .form-group input[type="url"],
.faq-form .form-group select,
.faq-form .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  font-size: 14px;
  color: #fff;
  line-height: 28px;
  padding: 10px 25px;
  background-color: transparent;
  font-weight: 400;
  height: 50px;
  border: 1px solid #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.faq-form .form-group input:focus,
.faq-form .form-group select:focus,
.faq-form .form-group textarea:focus {
  border-color: #555;
}
.faq-form .form-group textarea {
  height: 150px;
  resize: none;
}
.faq-form .form-group button {
  text-transform: uppercase;
  border: 0;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  margin-top: 10px;
  background: 0 0;
  border: 1px solid #fff;
}
.call-back-section {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 95px 0 70px;
  background-color: #f5f5f5;
}
.call-back-section.alternate {
  background-color: #fff;
}
.call-back-section:before {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 32%;
  background-color: maroon;
  border-radius: 0 0 0 150px;
  content: "";
}
.call-back-section .form-column {
  position: relative;
  margin-bottom: 50px;
}
.call-back-section .form-column .inner-column {
  position: relative;
  padding-top: 20px;
}
.call-back-section .sec-title {
  margin-bottom: 50px;
}
.call-back-section .form-group {
  position: relative;
  margin-bottom: 30px;
}
.call-back-section .form-group:last-child {
  margin-bottom: 0;
}
.request-form .form-group input[type="email"],
.request-form .form-group input[type="text"],
.request-form .form-group input[type="url"],
.request-form .form-group select,
.request-form .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  font-size: 14px;
  color: #222;
  line-height: 20px;
  padding: 15px 28px;
  border: 1px solid #bbb;
  background-color: #fff;
  font-weight: 400;
  height: 50px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.request-form .form-group input:focus,
.request-form .form-group select:focus,
.request-form .form-group textarea:focus {
  border-color: maroon;
}
.request-form .form-group textarea {
  height: 200px;
  resize: none;
}
.request-form .form-group button {
  text-transform: capitalize;
  font-size: 14px;
  padding: 15px 45px;
  line-height: 20px;
}
.call-back-section .image-column {
  position: relative;
  margin-bottom: 50px;
}
.call-back-section .image-column .inner-column {
  position: relative;
  margin-right: -23px;
}
.call-back-section .image-column .image {
  position: relative;
  margin-left: 22px;
  z-index: 2;
}
.call-back-section .image-column .image:before {
  position: absolute;
  left: -22px;
  top: 22px;
  height: 100%;
  width: 100%;
  background-color: #fff;
  border: 2px solid #a1a1a1;
  content: "";
  z-index: -1;
}
.call-back-section .image-column .image img {
  width: 100%;
  height: auto;
  display: inline-block;
}
.news-latters {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  padding: 120px 0 0;
  background-image: url(../images/light-bg-truck.jpeg);
}
.news-latters:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.6;
  content: "";
}
.news-latters .form-column {
  position: relative;
  margin-bottom: 50px;
}
.news-latters .form-column .inner-column {
  position: relative;
}
.news-latters .sec-title {
  margin-bottom: 50px;
}
.news-latters .sec-title .devider span {
  color: #fff;
}
.news-latters .sec-title .devider:after,
.news-latters .sec-title .devider:before {
  background-color: #fff;
}
.news-latters .form-column .text {
  display: block;
  font-size: 16px;
  line-height: 30px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 50px;
}
.newslatter-form {
  position: relative;
}
.newslatter-form .form-group {
  position: relative;
  margin-bottom: 30px;
}
.newslatter-form .form-group:last-child {
  margin-bottom: 0;
}
.newslatter-form .form-group input[type="email"],
.newslatter-form .form-group input[type="text"],
.newslatter-form .form-group input[type="url"],
.newslatter-form .form-group select,
.newslatter-form .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  font-size: 14px;
  color: #fff;
  line-height: 28px;
  padding: 15px 28px;
  border: 1px solid #fff;
  background-color: rgba(255, 255, 255, 0.1);
  font-weight: 400;
  height: 60px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.newslatter-form .form-group input:focus,
.newslatter-form .form-group select:focus,
.newslatter-form .form-group textarea:focus {
  border-color: maroon;
}
.newslatter-form .form-group textarea {
  height: 200px;
  resize: none;
}
.newslatter-form .form-group button {
  text-transform: capitalize;
  font-size: 14px;
  padding: 15px 45px;
  line-height: 25px;
  color: #000;
  font-family: Roboto, sans-serif;
}
.newslatter-form .form-group button:hover {
  color: #fff;
}
.news-latters .image-column {
  position: relative;
}
.news-latters .image-column .image-box {
  position: relative;
}
.news-latters .image-column .image {
  position: relative;
  margin-bottom: 0;
}
.news-section {
  position: relative;
  padding: 95px 0 60px;
}
.news-block {
  position: relative;
  margin-bottom: 40px;
}
.news-block .inner-box {
  position: relative;
  padding-right: 40px;
}
.news-block .image-box {
  position: relative;
}
.news-block .image-box .image {
  position: relative;
  margin-bottom: 0;
  overflow: hidden;
}
.news-block .image-box .icon-box {
  position: absolute;
  left: 0;
  top: 0;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  background-color: #252525;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.news-block .inner-box:hover .icon-box {
  background-color: maroon;
}
.news-block .image-box .owl-nav {
  position: absolute;
  left: 0;
  width: 100%;
  top: 50%;
  margin-top: -45px;
}
.news-block .image-box .owl-nav .owl-next,
.news-block .image-box .owl-nav .owl-prev {
  position: absolute;
  width: 25px;
  height: 90px;
  text-align: center;
  line-height: 90px;
  font-size: 17px;
  color: #fff;
  font-weight: 400;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.news-block .image-box .owl-nav .owl-next {
  left: auto;
  right: 0;
}
.news-block .image-box .owl-nav .owl-next:hover,
.news-block .image-box .owl-nav .owl-prev:hover {
  color: #222;
  background-color: rgba(255, 255, 255, 0.8);
}
.news-block .image-box .image img {
  display: block;
  width: 100%;
  height: auto;
}
.news-block .lower-content {
  position: relative;
  right: -40px;
  margin-top: -40px;
  padding: 25px 25px 30px;
  background-color: #111;
  box-shadow: 6px 8px 21px rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 9;
}
.news-block .inner-box:hover .lower-content {
  background-color: maroon;
}
.news-block .lower-content .post-info {
  position: relative;
  margin-bottom: 10px;
}
.news-block .lower-content .post-info li {
  position: relative;
  display: inline-block;
  padding-right: 15px;
  margin-right: 15px;
  font-size: 14px;
  line-height: 28px;
  color: #777;
  font-weight: 400;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.news-block .inner-box:hover .lower-content .post-info li,
.news-block .inner-box:hover .lower-content .post-info li:before {
  color: #fff;
}
.news-block .lower-content .post-info li a {
  color: #777;
}
.news-block .lower-content .post-info li:before {
  position: absolute;
  right: -3px;
  top: 0;
  font-size: 14px;
  line-height: 28px;
  color: #777;
  font-weight: 400;
  content: "|";
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.news-block .lower-content .post-info li:last-child:before {
  display: none;
}
.news-block .lower-content h3 {
  position: relative;
  display: block;
  font-size: 20px;
  line-height: 1.2em;
  color: #222;
  font-weight: 700;
  margin-bottom: 20px;
}
.news-block .lower-content h3 a {
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.news-block .inner-box:hover .lower-content h3 a {
  color: #fff;
}
.news-block .lower-content .text {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 30px;
  color: #777;
  font-weight: 400;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.news-block .inner-box:hover .lower-content .text {
  color: #fff;
}
.news-block .lower-content .link-box {
  position: relative;
}
.news-block .lower-content .link-box a {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 25px;
  color: maroon;
  text-transform: uppercase;
  font-weight: 500;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.news-block .lower-content .link-box a:hover {
  color: #222;
}
.news-block .lower-content .link-box a i {
  margin-left: 5px;
}
.news-section-two {
  position: relative;
  padding: 110px 0;
}
.news-block-two {
  position: relative;
}
.news-block-two .image-column {
  position: relative;
  margin-bottom: 0;
}
.news-block-two .image-column .inner-column {
  position: relative;
  padding-left: 30px;
  padding-bottom: 30px;
}
.news-block-two .image-column .image {
  position: relative;
  margin-bottom: 0;
}
.news-block-two .image-column .image:before {
  position: absolute;
  left: -30px;
  bottom: -30px;
  height: 100%;
  width: 100%;
  background-image: url(../images/icons/pattern-6.png);
  content: "";
}
.news-block-two .content-column {
  position: relative;
  padding-top: 90px;
}
.news-block-two .content-column .inner-column {
  position: relative;
  padding: 50px 55px 55px;
  background-color: #fff;
  box-shadow: 6px 8px 21px rgba(0, 0, 0, 0.15);
  z-index: 9;
  margin-left: -30px;
  margin-right: 70px;
}
.news-block-two .content-column h3 {
  position: relative;
  display: block;
  font-size: 30px;
  line-height: 48px;
  color: maroon;
  font-weight: 700;
  margin-bottom: 15px;
}
.news-block-two .content-column h3 a {
  color: maroon;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.news-block-two .content-column h3 a:hover {
  color: #222;
}
.news-block-two .content-column .post-info {
  position: relative;
  margin-bottom: 30px;
}
.news-block-two .content-column .post-info li {
  position: relative;
  display: inline-block;
  padding-right: 15px;
  margin-right: 15px;
  font-size: 16px;
  line-height: 28px;
  color: #222;
  font-weight: 400;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.news-block-two .content-column .post-info li a {
  color: #222;
}
.news-block-two .content-column .post-info li:before {
  position: absolute;
  right: -3px;
  top: 0;
  font-size: 16px;
  line-height: 28px;
  color: #222;
  font-weight: 400;
  content: "|";
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.news-block-two .content-column .post-info li:last-child:before {
  display: none;
}
.news-block-two .content-column .text {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 30px;
  color: #777;
  font-weight: 400;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.news-section-two .carousel-outer {
  position: relative;
}
.news-section-two .view-all {
  position: absolute;
  right: 70px;
  bottom: 15px;
  font-size: 18px;
  line-height: 1.2em;
  color: maroon;
  font-weight: 700;
  z-index: 99;
  font-family: Raleway, sans-serif;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.news-section-two .view-all:hover {
  color: #333;
}
.news-section-two .owl-nav {
  position: absolute;
  top: 15px;
  right: 70px;
}
.news-section-two .owl-next,
.news-section-two .owl-prev {
  position: relative;
  display: inline-block;
  height: 50px;
  width: 50px;
  text-align: center;
  color: #fff;
  background-color: #222;
  font-size: 24px;
  line-height: 50px;
  margin-left: 15px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.news-section-two .owl-next:hover,
.news-section-two .owl-prev:hover {
  background-color: maroon;
}
.blog-default {
  position: relative;
}
.news-section-three {
  position: relative;
  padding: 120px 0 80px;
}
.news-block-three {
  position: relative;
  padding-bottom: 50px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 35px;
}
.news-block-three .inner-box {
  position: relative;
}
.news-block-three .inner-box h4 {
  position: relative;
  font-size: 24px;
  line-height: 1.2em;
  color: #fff;
  font-weight: 700;
  margin-bottom: 5px;
}
.news-block-three .inner-box h4 a {
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.news-block-three .inner-box h4 a:hover {
  color: maroon;
}
.news-block-three .inner-box .info {
  position: relative;
  list-style-type: none;
  margin-bottom: 15px;
}
.news-block-three .inner-box .info li {
  position: relative;
  float: left;
  margin-right: 17px;
  padding-right: 17px;
  font-size: 14px;
  line-height: 50px;
  color: #777;
  font-weight: 400;
}
.news-block-three .inner-box .info li:before {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 14px;
  line-height: 50px;
  color: #777;
  content: "|";
}
.news-block-three .inner-box .info li:last-child:before {
  display: none;
}
.news-block-three .inner-box .info li i {
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: #777;
  margin-right: 7px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.news-block-three .inner-box .info li a {
  position: relative;
  display: inline-block;
  color: #777;
  font-weight: 400;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.news-block-three .inner-box .info li a:hover,
.news-block-three .inner-box .info li:hover i {
  color: maroon;
}
.news-block-three .image-box {
  position: relative;
}
.news-block-three .image-box .icon-box {
  position: absolute;
  right: 0;
  bottom: 35px;
  height: 45px;
  width: 70px;
  line-height: 45px;
  text-align: center;
  font-size: 24px;
  color: #fff;
  font-weight: 400;
  background-color: maroon;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.news-block-three .image-box .icon-box:before {
  position: absolute;
  bottom: 0;
  right: 100%;
  border-bottom: 45px solid maroon;
  border-left: 15px solid transparent;
  content: "";
}
.news-block-three .image-box .image {
  position: relative;
  margin-bottom: 0;
  overflow: hidden;
}
.news-block-three .image-box .image img {
  display: block;
  width: 85%;
  height: auto;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.news-block-three .inner-box:hover .image-box .image img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
.news-block-three .lower-content {
  position: relative;
  padding: 28px 0 0;
}
.news-block-three .lower-content .text {
  position: relative;
  display: block;
  font-size: 14px;
  max-width: 75%;
  line-height: 30px;
  color: #fff;
  font-weight: 400;
}
.news-block-three .lower-content .link-box {
  position: relative;
  margin-top: 20px;
}
.news-block-three .lower-content .link-box a {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  padding: 14px 40px;
  color: #fff;
  font-weight: 500;
  border: 1px solid maroon;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.news-block-three .lower-content .link-box a:hover {
  background-color: maroon;
  color: #fff;
}
.news-block-three .blockquote {
  position: relative;
  display: block;
  border: 2px solid #d7d7d7;
  padding: 50px 50px;
}
.news-block-three .blockquote:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  content: "";
  opacity: 0.8;
}
.news-block-three .blockquote h2 {
  position: relative;
  display: block;
  font-size: 36px;
  line-height: 60px;
  color: #fff;
  font-weight: 500;
  font-family: Oswald, sans-serif;
  margin-bottom: 70px;
}
.news-block-three .blockquote h2 span {
  color: maroon;
  text-transform: uppercase;
}
.news-block-three .blockquote .author-info {
  position: relative;
  display: inline-block;
  text-align: right;
}
.news-block-three .blockquote .author {
  position: relative;
  display: block;
  line-height: 25px;
  color: maroon;
  font-size: 18px;
  text-decoration: underline;
  font-family: Oswald, sans-serif;
}
.news-block-three .blockquote .designation {
  position: relative;
  display: block;
  font-size: 12px;
  line-height: 1em;
  color: #fff;
  margin-top: 5px;
  font-weight: 400;
}
.news-block-three .single-item-carousel {
  position: relative;
}
.news-block-three .image-box .owl-nav {
  position: absolute;
  left: 0;
  width: 100%;
  top: 50%;
  margin-top: -60px;
}
.news-block-three .image-box .owl-nav .owl-next,
.news-block-three .image-box .owl-nav .owl-prev {
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 120px;
  text-align: center;
  line-height: 120px;
  font-size: 18px;
  color: #222;
  font-weight: 400;
  background-color: rgba(255, 255, 255, 0.7);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.news-block-three .image-box .owl-nav .owl-next {
  left: auto;
  right: 0;
}
.news-block-three .image-box .owl-nav .owl-next:hover,
.news-block-three .image-box .owl-nav .owl-prev:hover {
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
}
.blog-default .styled-pagination {
  margin-top: 50px;
}
.blog-detail {
  position: relative;
}
.blog-detail .news-block-three {
  position: relative;
  padding-bottom: 0;
  border-bottom: 0;
}
.blog-detail .news-block-three h4 {
  margin-bottom: 20px;
}
.blog-detail .news-block-three h5 {
  position: relative;
  display: block;
  font-size: 20px;
  line-height: 1.2em;
  color: #fa0;
  font-weight: 700;
  margin-bottom: 20px;
}
.blog-detail .news-block-three p {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 30px;
  color: #777;
  font-weight: 400;
  margin-bottom: 20px;
}
.blog-detail .news-block-three .lower-content {
  padding-top: 10px;
}
.blog-detail .news-block-three .lower-content .info {
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}
.blog-detail .post-images {
  position: relative;
  margin-bottom: 10px;
  margin-top: 60px;
}
.blog-detail .post-images .image {
  margin-bottom: 30px;
}
.blog-detail .post-images .image img {
  display: block;
  width: 100%;
  height: auto;
}
.blog-detail blockquote {
  position: relative;
  max-width: 700px;
  width: 100%;
  margin: 0 auto 50px;
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.19);
  padding: 35px 35px 25px;
}
.blog-detail blockquote .icon {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 20px;
  color: #777;
  font-weight: 400;
  margin-bottom: 10px;
}
.blog-detail blockquote cite {
  position: relative;
  /* float: right; */
  font-size: 18px;
  line-height: 1em;
  color: #fff;
  display: inline-block;
  font-weight: 700;
  font-style: normal;
  font-family: Raleway, sans-serif;
  padding-left: 10px;
}
.blog-detail blockquote cite:before {
  position: absolute;
  top: 8px;
  right: 100%;
  height: 3px;
  width: 37px;
  background-color: #fa0;
  content: "";
}
.blog-detail .post-share-options {
  position: relative;
  margin-bottom: 30px;
}
.blog-detail .post-share-options .icon {
  position: relative;
  float: left;
  height: 50px;
  width: 50px;
  background-color: #ebebeb;
  text-align: center;
  font-size: 18px;
  color: #222;
  line-height: 50px;
  border-radius: 50%;
}
.blog-detail .post-share-options .tags {
  position: relative;
  float: left;
  padding: 8px 25px;
}
.blog-detail .post-share-options .tags li {
  position: relative;
  display: inline-block;
}
.blog-detail .post-share-options .tags a {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  color: #777;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.blog-detail .post-share-options .tags a:hover {
  color: maroon;
}
.blog-detail .post-share-options .social-icon {
  position: relative;
  float: left;
  margin: 0;
  list-style-type: none;
  padding: 10px 13px;
}
.blog-detail .post-share-options .social-icon li {
  position: relative;
  float: left;
  margin: 0 13px;
}
.blog-detail .post-share-options .social-icon li a {
  position: relative;
  display: block;
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
  color: #777;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.blog-detail .post-share-options .social-icon li a:hover {
  color: maroon;
}
.blog-post-controls {
  position: relative;
  text-align: left;
  padding: 40px 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin-bottom: 50px;
}
.blog-post-controls .inner {
  position: relative;
}
.blog-post-controls:before {
  position: absolute;
  left: 50%;
  top: 0;
  height: 100%;
  border-right: 1px solid #ddd;
  content: "";
}
.blog-post-controls .thumb {
  position: absolute;
  left: 0;
  top: 0;
}
.blog-post-controls .title {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 30px;
  color: #777;
  font-weight: 400;
}
.blog-post-controls h5 {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  color: #222;
  font-weight: 700;
  max-width: 230px;
}
.blog-post-controls h5 a {
  display: inline-block;
  color: #222;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.blog-post-controls h5 a:hover {
  color: maroon;
}
.blog-post-controls .prev-post {
  position: relative;
  float: left;
  width: 50%;
  padding-left: 100px;
}
.blog-post-controls .next-post {
  position: relative;
  float: right;
  width: 50%;
  text-align: right;
  padding-right: 100px;
  padding-left: 0;
}
.blog-post-controls .next-post .thumb {
  left: auto;
  right: 0;
}
.styled-pagination {
  position: relative;
  display: block;
  width: 100%;
}
.styled-pagination ul {
  position: relative;
  width: 100%;
  margin-bottom: 0;
  list-style-type: none;
}
.styled-pagination li {
  position: relative;
  display: inline-block;
  margin: 0 4px 8px;
}
.styled-pagination li a {
  position: relative;
  display: block;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  font-weight: 400;
  font-size: 14px;
  background: #fff;
  color: #222;
  border: 1px dashed #d7d7d7;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.styled-pagination li a:hover,
.styled-pagination li.active a {
  color: #fff;
  background: maroon;
  border-color: maroon;
}
.styled-pagination li.next a,
.styled-pagination li.prev a {
  min-width: 70px;
  background-color: #222;
  color: #fff;
  border: 0;
}
.styled-pagination li.next a:hover,
.styled-pagination li.prev a:hover {
  color: #fff;
  background: maroon;
  border-color: maroon;
}
.clients-section {
  position: relative;
  padding: 100px 0;
  background-color: #f5f5f5;
}
.clients-section .sponsors-outer {
  position: relative;
}
.clients-section .sponsors-carousel {
  position: relative;
  list-style-type: none;
  margin: 0;
}
.clients-section .slide-item {
  position: relative;
  list-style: none;
}
.clients-section .image-box {
  position: relative;
  margin: 0;
  text-align: center;
}
.clients-section .image-box img {
  display: inline-block;
  max-width: 100%;
  width: auto;
  height: auto;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.clients-section .image-box:hover img {
  opacity: 0.5;
}
.clients-section .sponsors-carousel .owl-dots,
.clients-section .sponsors-carousel .owl-nav {
  display: none;
}
.clients-section.style-two {
  background-color: #222;
}
.clients-section.style-three {
  position: relative;
  background-color: #fff;
  padding: 120px 0;
}
.contact-form-section {
  position: relative;
  padding: 120px 0 90px;
}
.contact-form-section .sec-title {
  margin-bottom: 50px;
}
.contact-form .form-group {
  position: relative;
  margin-bottom: 30px;
}
.contact-form .form-group input[type="email"],
.contact-form .form-group input[type="text"],
.contact-form .form-group input[type="url"],
.contact-form .form-group select,
.contact-form .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  font-size: 14px;
  color: #777;
  line-height: 28px;
  padding: 10px 25px;
  background-color: #fff;
  font-weight: 400;
  height: 50px;
  border: 1px solid #ddd;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.contact-form .form-group input:focus,
.contact-form .form-group select:focus,
.contact-form .form-group textarea:focus {
  border-color: maroon;
}
.contact-form .form-group textarea {
  height: 190px;
  resize: none;
  border-radius: 20px;
  padding-top: 20px;
}
.contact-form label.error {
  color: red;
}
.contact-form .form-group button {
  border: 0;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  margin-top: 10px;
  font-weight: 700;
  border-radius: 5px;
  padding: 10px 40px;
}
.contact-info-section {
  position: relative;
  padding: 85px 0 35px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(../images/icons/pattern-4.jpg);
}
.contact-info-section .auto-container {
  max-width: 1750px !important;
}
.portfoliodetail-container {
  background-image: url(../images/background/portfolio.jpg);
  position: relative;
  /* padding: 85px 0 35px; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 22rem;
}
.title-seting {
  width: fit-content !important;
  /* padding-right: 10rem; */
}
.contact-info-section .title-column {
  position: relative;
  margin-bottom: 50px;
}
.contact-info-section .title-column h2 {
  position: relative;
  display: inline-block;
  font-size: 36px;
  line-height: 1.2em;
  font-weight: 700;
  color: #fff;
  padding-right: 40px;
  margin-bottom: 25px;
}
.contact-info-section .title-column h2 span {
  color: #bbb;
}
.contact-info-section .title-column h2:before {
  position: absolute;
  right: 0;
  bottom: 8px;
  height: 2px;
  width: 30px;
  /* background-color: maroon; */
  background: rgb(121, 15, 15);
  background: linear-gradient(
    90deg,
    rgba(121, 15, 15, 1) 0%,
    rgba(247, 2, 2, 1) 72%,
    rgba(247, 2, 2, 1) 100%,
    rgba(64, 63, 63, 1) 100%
  );
  content: "";
}
.contact-info-section .title-column .text {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 30px;
  color: #fff;
  font-weight: 400;
  max-width: 460px;
}
.contact-info-block {
  position: relative;
  margin-bottom: 50px;
}
.contact-info-block .inner {
  position: relative;
  padding-left: 100px;
}
.contact-info-block .icon {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 60px;
  line-height: 1em;
  color: #aaa;
}
.contact-info-block h3 {
  display: block;
  font-size: 30px;
  line-height: 1em;
  color: maroon;
  background: rgb(121, 15, 15);
  background: linear-gradient(
    90deg,
    rgba(121, 15, 15, 1) 0%,
    rgba(247, 2, 2, 1) 72%,
    rgba(247, 2, 2, 1) 100%,
    rgba(64, 63, 63, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  margin-bottom: 15px;
}
.contact-info-block .info {
  position: relative;
  font-size: 18px;
  line-height: 26px;
  color: #fff;
  font-weight: 400;
}
.contact-info-block .info a {
  color: #fff;
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.contact-info-block .info a:hover {
  text-decoration: underline;
}
.contact-info-section .block-column {
  position: relative;
}
.contact-info-section .block-column .inner-column {
  position: relative;
  padding-top: 55px;
}
.contact-info-section.style-two {
  position: relative;
  background-color: maroon;
  background-image: none;
}
.contact-info-section.style-two:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: url(../images/icons/pattern-8.png),
    url(../images/icons/pattern-8.png);
  background-position: center;
  content: "";
  opacity: 0.5;
}
.contact-info-section.style-two .contact-info-block h3,
.contact-info-section.style-two .title-column h2 span {
  color: #000;
}
.contact-info-section.style-two .title-column h2:before {
  background-color: #000;
}
.contact-info-section.style-two .contact-info-block .icon {
  color: #fff;
}
.contact-info-section.style-three {
  position: relative;
  background-color: #000;
  background-image: none;
}
.contact-info-section.style-three .contact-info-block .icon,
.contact-info-section.style-three .title-column h2 {
  color: #fff;
}
.contact-info-section.style-three .contact-info-block {
  margin-bottom: 85px;
}
.contact-info-section.style-three .contact-info-block h3,
.contact-info-section.style-three .title-column h2 span {
  color: maroon;
}
.contact-info-section.style-three .title-column h2:before {
  background-color: maroon;
}
.contact-info-section.style-three .contact-info-block .info,
.contact-info-section.style-three .contact-info-block .info a,
.contact-info-section.style-three .title-column .text {
  color: #777;
  max-width: 360px;
}
.contact-info-section.style-three .block-column .inner-column {
  padding-top: 15px;
}
.contact-info-section.style-three .contact-info-block h3 {
  font-size: 24px;
}
.contact-map-section {
  position: relative;
  height: 500px;
}
.contact-map-section .map-outer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #343434;
}
.contact-map-section .map-canvas {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.map-canvas {
  position: relative;
}
.map-data {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8em;
}
.map-data a {
  display: block;
}
.map-data h6 {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 5px;
  color: #121212;
}
.main-footer {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #000;
}
.main-footer .footer-upper .auto-container{
 max-width: 1750px !important;
}
.main-footer .footer-bottom .auto-container{
  max-width: 1750px !important;
 }
.main-footer .footer-upper {
  position: relative;
  padding: 40px 0 85px;
}
.main-footer .footer-column {
  position: relative;
}
.main-footer .footer-widget {
  position: relative;
  margin-bottom: 40px;
}
hr {
  border: 1px solid #fff !important;
}
.main-footer .footer-column .widget-title {
  position: relative;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  line-height: 30px;
  padding-bottom: 10px;
  margin-bottom: 50px;
}
.main-footer .footer-column .widget-title:before {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 60px;
  background: rgb(121, 15, 15);
  background: linear-gradient(
    90deg,
    rgba(121, 15, 15, 1) 0%,
    rgba(247, 2, 2, 1) 72%,
    rgba(247, 2, 2, 1) 100%,
    rgba(64, 63, 63, 1) 100%
  );
  -webkit-text-fill-color: transparent;
  content: "";
}
.main-footer .about-widget {
  position: relative;
}
.main-footer .logo {
  position: relative;
  top: -20px;
  margin-bottom: 30px;
}
.main-footer .footer-logo figure {
  margin: 0;
}
.main-footer .footer-logo img {
  display: block;
  max-width: 100%;
}
.main-footer .about-widget .text {
  position: relative;
  margin-bottom: 40px;
}
.main-footer .about-widget .text p {
  font-size: 14px;
  line-height: 30px;
  color: #888;
  font-weight: 400;
  margin-bottom: 30px;
}
.main-footer .about-widget .text p strong {
  color: #fff;
  font-weight: 700;
}
.main-footer .social-icon-two li a {
  color: #888;
}
.social-icon-two {
  position: relative;
  display: block;
  margin: 0;
}
.social-icon-two .title {
  position: relative;
  list-style-type: none;
  font-size: 20px;
  line-height: 26px;
  color: #fff;
  font-weight: 700;
  font-family: Raleway, sans-serif;
  margin-right: 25px;
}
.social-icon-two li {
  position: relative;
  list-style-type: none;
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  margin-right: 22px;
}
.social-icon-two li a {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.social-icon-two li a:hover {
  color: maroon;
}
.recent-posts {
  position: relative;
}
.recent-posts .post {
  position: relative;
  min-height: 90px;
  padding-left: 100px;
  margin-bottom: 40px;
}
.recent-posts .post:last-child {
  margin-bottom: 0;
}
.recent-posts .post .thumb {
  position: absolute;
  left: 0;
  top: 0;
  height: 80px;
  width: 80px;
  overflow: hidden;
}
.recent-posts .post .thumb a {
  display: block;
}
.recent-posts .post .thumb img {
  display: block;
  width: 100%;
  height: auto;
}
.recent-posts .post h4 {
  position: relative;
  font-size: 16px;
  color: #fff;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 5px;
}
.recent-posts .post h4 a {
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.recent-posts .post h4 a:hover {
  color: maroon;
}
.recent-posts .post .date {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 30px;
  color: #777;
}
.main-footer .services-widget {
  position: relative;
}
.main-footer .services-widget .widget-content .widget-title {
  margin-bottom: 20px;
}
.serivce-list {
  position: relative;
  margin-bottom: 15px;
}
.serivce-list li {
  position: relative;
  display: block;
  list-style-type: none;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  font-weight: 400;
  padding-left: 25px;
  margin-bottom: 20px;
}
.serivce-list li:before {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 14px;
  line-height: 20px;
  /* color: #777; */
  background: linear-gradient(
    90deg,
    rgba(121, 15, 15, 1) 0%,
    rgba(247, 2, 2, 1) 51%,
    rgba(247, 2, 2, 1) 87%,
    rgba(64, 63, 63, 1) 82%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  content: "\f105";
  font-weight: 900;
  font-family: FontAwesome;
}
.serivce-list li a {
  display: block;
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.serivce-list li a:hover {
  color: maroon;
}
.newslatter-widget {
  position: relative;
}
.newslatter-widget h4 {
  position: relative;
  font-size: 16px;
  line-height: 30px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 3px;
}
.main-footer .newslatter-form {
  position: relative;
  margin-bottom: 30px;
}
.main-footer .newslatter-form .form-group {
  position: relative;
  display: block;
  margin: 0;
  width: 100%;
}
.main-footer .newslatter-form .form-group input[type="email"],
.main-footer .newslatter-form .form-group input[type="tel"],
.main-footer .newslatter-form .form-group input[type="text"],
.main-footer .newslatter-form .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  line-height: 20px;
  height: 50px;
  font-size: 14px;
  color: #777;
  background-color: #fff;
  padding: 14px 20px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.main-footer .newslatter-form .form-group button,
.main-footer .newslatter-form .form-group input[type="submit"] {
  position: relative;
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  background-color: #222;
  padding: 10px 30px;
  line-height: 30px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.main-footer .newslatter-form .form-group button:hover,
.main-footer .newslatter-form .form-group input[type="submit"]:hover {
  background-color: #fff;
  color: #222;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.newslatter-widget .social-icon-two li {
  margin-right: 20px;
}
.main-footer .footer-bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgb(121, 15, 15);
  background: linear-gradient(
    90deg,
    rgba(121, 15, 15, 1) 0%,
    rgba(247, 2, 2, 1) 72%,
    rgba(247, 2, 2, 1) 100%,
    rgba(64, 63, 63, 1) 100%
  );
  /* background: rgb(3, 2, 2);
  background: linear-gradient(
    90deg,
    rgba(3, 2, 2, 1) 0%,
    rgba(10, 2, 2, 1) 0%,
    rgba(88, 1, 1, 1) 0%,
    rgba(107, 4, 4, 1) 13%,
    rgba(247, 5, 5, 1) 100%,
    rgba(227, 0, 0, 1) 100%
  ); */
}
.main-footer .footer-bottom .inner-container {
  position: relative;
}
.main-footer .footer-bottom .copyright-text {
  position: relative;
  float: left;
  padding: 24px 0;
}
.main-footer .footer-bottom .copyright-text p {
  position: relative;
  line-height: 30px;
  font-size: 14px;
  color: #fff;
  font-weight: 400;
}
.main-footer .footer-bottom .copyright-text a {
  color: #000;
  padding-left: 0.5rem;
  font-weight: 700;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.main-footer .footer-bottom .copyright-text a:hover {
  color: #fff;
}
.main-footer .footer-bottom .footer-nav {
  position: relative;
  float: right;
}
.main-footer .footer-bottom .footer-nav ul {
  position: relative;
  padding: 25px 70px;
  margin: 0;
}
.main-footer .footer-bottom .footer-nav ul li {
  list-style-type: none;
  position: relative;
  float: left;
  padding-left: 30px;
  margin-left: 30px;
}
.main-footer .footer-bottom .footer-nav ul li:first-child {
  margin-left: 0;
  padding-left: 0;
}
.main-footer .footer-bottom .footer-nav ul li a {
  display: inline-block;
  font-size: 14px;
  line-height: 30px;
  color: #fff;
  font-weight: 700;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.main-footer .footer-bottom .footer-nav ul li:before {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 15px;
  line-height: 20px;
  color: #fff;
  font-weight: 400;
  content: "|";
}
.main-footer .footer-bottom .footer-nav ul li a:hover {
  color: #222;
}
.main-footer .footer-bottom .footer-nav ul li:first-child:before {
  display: none;
}
.main-footer.style-two:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.9;
  content: "";
}
.main-footer.style-two .about-widget .text p,
.main-footer.style-two .footer-column .widget-title,
.main-footer.style-two .newslatter-widget h4,
.main-footer.style-two .recent-posts .post h4 a,
.main-footer.style-two .serivce-list li a,
.main-footer.style-two .serivce-list li:before,
.main-footer.style-two .social-icon-two .title,
.main-footer.style-two .social-icon-two li a,
.main-footer.style-two .user-links li a,
.main-footer.style-two .user-links li:before {
  color: #fff;
}
.main-footer.style-two .about-widget .text p strong,
.main-footer.style-two .footer-bottom .copyright-text a,
.main-footer.style-two .recent-posts .post h4 a:hover,
.main-footer.style-two .serivce-list li a:hover,
.main-footer.style-two .social-icon-two li a:hover,
.main-footer.style-two .user-links li a:hover {
  color: maroon;
}
.main-footer.style-two .recent-posts .post .date {
  color: #ddd;
}
.main-footer.style-two .newslatter-form .form-group button,
.main-footer.style-two .newslatter-form .form-group input[type="submit"] {
  background-color: maroon;
  color: #fff;
}
.main-footer.style-two .newslatter-form .form-group button:hover,
.main-footer.style-two .newslatter-form .form-group input[type="submit"]:hover {
  background-color: #fff;
  color: maroon;
}
.main-footer.style-two .footer-bottom {
  background-color: #555;
}
.main-footer.style-two.alternate .footer-bottom {
  background-color: maroon;
}
.main-footer.style-two.alternate .footer-bottom .copyright-text a {
  color: #222;
}
.main-footer.style-three:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.9;
  content: "";
}
.main-footer.style-three .about-widget .text p strong,
.main-footer.style-three .footer-column .widget-title,
.main-footer.style-three .recent-posts .post h4 a,
.main-footer.style-three .social-icon-two .title {
  color: #fff;
}
.main-footer.style-three .recent-posts .post h4 a:hover,
.main-footer.style-three .user-links li a:hover {
  color: maroon;
}
.main-footer.style-three .recent-posts .post .date {
  color: #ddd;
}
.main-footer.style-three .serivce-list li:before {
  background-color: #555;
}
.main-footer.style-four .serivce-list {
  top: 20px;
  left: 10px;
}
.main-footer.style-four .serivce-list li {
  width: 100%;
}
.main-footer.style-four .user-links {
  top: 10px;
}
.main-footer.style-four .user-links li {
  display: block;
  line-height: 36px;
}
.sidebar-page-container {
  position: relative;
  overflow: hidden;
  padding: 120px 0 70px;
}
.sidebar-page-container .content-side,
.sidebar-page-container .sidebar-side {
  position: relative;
  margin-bottom: 0;
}
.sidebar-widget {
  position: relative;
  margin-bottom: 55px;
}
.sidebar-side .sidebar-widget:last-child {
  margin-bottom: 0;
}
.sidebar-page-container .sidebar-title {
  position: relative;
  margin-bottom: 35px;
}
.sidebar-page-container .sidebar-title h2 {
  position: relative;
  display: inline-block;
  font-size: 24px;
  line-height: 1.2em;
  color: #222;
  font-weight: 700;
  text-transform: capitalize;
}
.sidebar-page-container .sidebar-title .devider {
  position: relative;
  display: block;
  margin-top: 10px;
  width: 150px;
  text-align: center;
}
.sidebar-page-container .sidebar-title .devider:before {
  position: absolute;
  left: 0;
  top: 14px;
  height: 1px;
  width: 52px;
  background-color: #777;
  content: "";
}
.sidebar-page-container .sidebar-title .devider span {
  position: relative;
  display: inline-block;
  font-size: 22px;
  line-height: 30px;
  color: #777;
  font-weight: 400;
}
.sidebar .search-box {
  position: relative;
}
.sidebar .search-box .form-group {
  position: relative;
  margin: 0;
}
.sidebar .search-box .form-group input[type="search"],
.sidebar .search-box .form-group input[type="text"] {
  position: relative;
  display: block;
  font-size: 14px;
  color: #777;
  line-height: 28px;
  padding: 15px 25px;
  padding-right: 60px;
  height: 60px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.sidebar .search-box .form-group input:focus {
  border-color: maroon;
}
.sidebar .search-box .form-group button {
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  display: block;
  font-size: 18px;
  background-color: maroon;
  color: #222;
  font-weight: 400;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.sidebar .search-box .form-group button:hover {
  color: #fff;
}
.cat-list {
  position: relative;
  margin: 0;
  list-style-type: none;
}
.cat-list li {
  position: relative;
  margin-bottom: 2px;
}
.cat-list li a {
  position: relative;
  padding: 20px 28px;
  display: block;
  font-size: 16px;
  font-weight: 400;
  color: #777;
  line-height: 25px;
  padding-left: 50px;
  background-color: #eee;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.cat-list li a:before {
  position: absolute;
  content: "\f0da";
  left: 30px;
  top: 20px;
  font-family: FontAwesome;
}
.cat-list li a span {
  float: right;
}
.cat-list li a:hover,
.cat-list li.active a {
  color: maroon;
  background-color: #333;
  font-weight: 700;
}
.latest-news {
  position: relative;
}
.latest-news .recent-post {
  position: relative;
  padding-left: 125px;
  margin-bottom: 20px;
}
.latest-news .recent-post:last-child {
  margin-bottom: 0;
}
.latest-news .recent-post .image-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100px;
  background-color: #333;
}
.latest-news .recent-post .image-box img {
  position: relative;
  display: block;
  width: 100%;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.latest-news .recent-post:hover .image-box img {
  opacity: 0.3;
}
.latest-news .recent-post h3 {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 24px;
  color: #222;
  font-weight: 600;
  margin-bottom: 3px;
}
.latest-news .recent-post h3 a {
  color: #222;
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.latest-news .recent-post:hover h3 a {
  color: maroon;
}
.latest-news .recent-post .views {
  display: block;
  font-size: 14px;
  color: #777;
  font-weight: 400;
  line-height: 24px;
}
.latest-news .recent-post .views span {
  font-size: 16px;
  line-height: 24px;
  color: #333;
}
.tag-list {
  position: relative;
  display: block;
  margin: 0;
  list-style-type: none;
}
.tag-list li {
  position: relative;
  float: left;
  margin-bottom: 10px;
  margin-right: 10px;
}
.tag-list li a {
  position: relative;
  display: block;
  font-size: 16px;
  color: #777;
  line-height: 20px;
  padding: 15px 22px;
  font-weight: 500;
  text-align: center;
  background-color: #f5f5f5;
  font-family: Raleway, sans-serif;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.tag-list li a:hover {
  background-color: #222;
  color: maroon;
  border-radius: 50px;
}
.group-title {
  position: relative;
  margin-bottom: 50px;
}
.group-title h2 {
  position: relative;
  display: block;
  font-size: 30px;
  line-height: 1.2em;
  color: #222;
  font-weight: 600;
}
.comments-area {
  position: relative;
  margin-bottom: 65px;
}
.comments-box {
  position: relative;
}
.comments-area .comment-box {
  position: relative;
  padding: 30px 65px;
  margin-left: 100px;
  border: 1px solid #ddd;
  margin-bottom: 70px;
}
.comments-area .comment-box.reply-comment {
  margin-left: 170px;
}
.comments-area .comment {
  position: relative;
  min-height: 120px;
}
.comments-area .comment-box .author-thumb {
  position: absolute;
  left: -170px;
  top: 0;
  height: 120px;
  width: 120px;
}
.comments-area .comment-box .author-thumb img {
  display: block;
  width: 100%;
  height: auto;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.19);
}
.comments-area .comment-info {
  position: relative;
  display: block;
  margin-bottom: 10px;
  font-family: Raleway, sans-serif;
}
.comments-area .comment-box .name {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 30px;
  font-weight: 600;
  color: #222;
}
.comments-area .comment-box .date {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 30px;
  color: #222;
  font-weight: 400;
  margin-left: 15px;
}
.comments-area .comment-box .text {
  font-size: 14px;
  line-height: 30px;
  color: #777;
  font-weight: 400;
}
.comments-area .comment-box .reply-btn {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 30px;
  color: #ff9d42;
  font-weight: 700;
  font-family: Raleway, sans-serif;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.comments-area .comment-box .reply-btn:hover {
  color: #222;
}
.sidebar-page-container .comment-form {
  position: relative;
}
.comment-form .group-title {
  margin-bottom: 40px;
}
.comment-form .form-group {
  position: relative;
  margin-bottom: 20px;
}
.comment-form .form-group:last-child {
  margin-bottom: 0;
}
.comment-form .form-group input[type="email"],
.comment-form .form-group input[type="text"],
.comment-form .form-group input[type="url"],
.comment-form .form-group select,
.comment-form .form-group textarea {
  position: relative;
  display: block;
  height: 50px;
  width: 100%;
  font-size: 14px;
  color: #777;
  line-height: 20px;
  font-weight: 400;
  padding: 14px 30px;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.comment-form .form-group input:focus,
.comment-form .form-group select:focus,
.comment-form .form-group textarea:focus {
  border-color: maroon;
}
.comment-form .form-group textarea {
  height: 270px;
  resize: none;
  padding-top: 25px;
  border-radius: 10px;
}
.comment-form button {
  color: #fff;
  text-transform: capitalize;
  font-size: 14px;
  padding: 12px 35px;
  line-height: 20px;
}
.coming-soon {
  position: fixed;
  width: 100%;
  height: 100%;
  display: block;
  overflow-y: auto;
  background-repeat: no-repeat;
  background-size: cover;
}
.coming-soon .content:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  content: "";
}
.coming-soon .content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: table;
  vertical-align: middle;
}
.coming-soon .content .content-inner {
  position: relative;
  padding: 90px 15px;
  display: table-cell;
  vertical-align: middle;
}
.coming-soon .content .content-inner .logo {
  position: relative;
  margin-bottom: 100px;
}
.coming-soon .content .content-inner h2 {
  position: relative;
  color: #fff;
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2em;
  margin-bottom: 85px;
}
.coming-soon .content .content-inner h2 span {
  color: maroon;
}
.time-counter {
  position: relative;
}
.time-counter .time-countdown {
  position: relative;
  margin-bottom: 60px;
}
.time-counter .time-countdown .counter-column {
  position: relative;
  float: left;
  margin-right: 20px;
  width: 210px;
  height: 210px;
  font-size: 24px;
  line-height: 30px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  border: 2px dotted #fff;
  margin-bottom: 20px;
}
.time-counter .time-countdown .counter-column:before {
  position: absolute;
  left: -2px;
  top: -2px;
  border-top: 60px solid maroon;
  border-right: 60px solid transparent;
  content: "";
}
.time-counter .time-countdown .counter-column .count {
  position: relative;
  display: block;
  font-size: 72px;
  line-height: 50px;
  padding: 60px 0 30px;
  color: #fff;
  font-weight: 700;
}
.coming-soon .emailed-form {
  position: relative;
  margin-bottom: 65px;
}
.coming-soon .emailed-form h4 {
  position: relative;
  display: block;
  font-size: 30px;
  line-height: 1.2em;
  color: #fff;
  font-weight: 700;
  margin-bottom: 40px;
}
.coming-soon .emailed-form .form-group {
  position: relative;
  display: block;
  max-width: 740px;
  width: 100%;
}
.coming-soon .emailed-form .form-group input[type="email"],
.coming-soon .emailed-form .form-group input[type="tel"],
.coming-soon .emailed-form .form-group input[type="text"],
.coming-soon .emailed-form .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  line-height: 28px;
  height: 60px;
  font-size: 18px;
  color: #777;
  overflow: hidden;
  padding: 15px 50px 15px 30px;
  background: 0 0;
  border: 1px solid #e1e1e1;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.coming-soon .emailed-form .form-group input:focus,
.coming-soon .emailed-form .form-group select:focus,
.coming-soon .emailed-form .form-group textarea:focus {
  border-color: #fff;
  color: #fff;
}
.coming-soon .emailed-form .form-group input[type="submit"],
.coming-soon .emailed-form button {
  position: absolute;
  right: 0;
  top: 0;
  width: 165px;
  height: 60px;
  line-height: 40px;
  padding: 10px 50px;
  font-size: 18px;
}
.coming-soon .social-icon-two {
  margin-bottom: 40px;
}
.coming-soon .social-icon-two li {
  margin-right: 30px;
}
.coming-soon .social-icon-two li:last-child {
  margin-right: 0;
}
.coming-soon .social-icon-two li a {
  font-size: 20px;
  line-height: 30px;
}
.coming-soon .copyright-text {
  position: relative;
  font-size: 18px;
  line-height: 26px;
  color: #fff;
  font-weight: 400;
}
.coming-soon .copyright-text a {
  color: maroon;
}
.coming-soon .copyright-text a:hover {
  text-decoration: underline;
}
.main-slider.style-three:before {
  display: none;
}
.main-slider.style-three .tp-dottedoverlay {
  display: none;
}
.main-slider.style-three {
  margin-top: 0;
  color: #fff !important;
  border-top: 4px solid maroon;
}
.main-slider.style-three .bg-overlay {
  background: #000;
  opacity: 0.6 !important;
  z-index: 0;
}
.main-slider.style-three h6 {
  font-size: 18px !important;
  font-weight: 700 !important;
  color: maroon !important;
  letter-spacing: 1px !important;
  word-spacing: 1px;
  font-family: Roboto, sans-serif;
  z-index: 1;
}
.main-slider.style-three .divider {
  width: 60px;
  height: 2px;
  background: #fff;
  text-align: center;
  z-index: 1;
}
.main-slider.style-three h2 {
  font-size: 84px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 30px;
  line-height: 1;
  text-align: left;
  font-family: Raleway, sans-serif;
  z-index: 1;
}
.main-slider.style-three h5 {
  font-size: 24px !important;
  font-weight: 700 !important;
  color: #fff;
  font-family: Roboto, sans-serif;
  letter-spacing: 1px !important;
  z-index: 1;
}
.main-slider.style-three a {
  font-size: 16px !important;
  line-height: 30px !important;
  padding: 13px 40px !important;
  font-weight: 400 !important;
  background-color: maroon !important;
  transition: all 0.3s linear !important;
  z-index: 1;
}
.service_section {
  position: relative;
  padding: 120px 0 70px;
}
.service1_slider {
  padding-right: 30px;
}
.service1_slider .sec-title {
  margin-bottom: 30px;
}
.service1_slider .content p {
  padding-bottom: 20px;
}
.service1_slider .owl-nav {
  text-align: left;
  padding-top: 20px;
}
.service1_slider .owl-next,
.service1_slider .owl-prev {
  position: relative;
  display: inline-block;
  height: 35px;
  width: 35px;
  text-align: center;
  border: 1px solid #404040;
  color: maroon;
  font-size: 20px;
  line-height: 33px;
  margin: 0 12px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.service1_slider .owl-next .fa,
.service1_slider .owl-prev .fa {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.ser_slider .owl-prev:hover,
.service1_slider .owl-next:hover {
  color: #404040;
  border-color: maroon;
}
.sec-right {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
}
.services-carousel.style_two .service-block .caption-box {
  padding: 20px 10px;
  padding-left: 60px;
}
.service-block .caption-box .icon {
  width: 40px;
  font-size: 20px;
}
.services-carousel.style_two .service-block .caption-box h4 {
  font-size: 16px;
}
.services-carousel.style_two .service-block .overlay-box {
  padding: 0 20px 50px;
}
.service-block .overlay-box h5 {
  font-size: 15px;
  margin-bottom: 15px;
}
.service-block .overlay-box .text {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 15px;
}
.service-block .overlay-box .icon {
  margin-bottom: 15px;
}
.services-carousel.style_two .owl-nav {
  display: none;
}
.active .item .detail_box {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 0.4s;
  -moz-transition-delay: 0.4s;
  -ms-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}
.item .detail_box {
  width: 400px;
  height: auto;
  padding: 40px 30px;
  background: #fff;
  box-shadow: 5px 0 12px 0 #ccc;
  margin-right: 50px;
  margin-left: auto;
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transform: translateY(-20%);
  -moz-transform: translateY(-20%);
  -ms-transform: translateY(-20%);
  -o-transform: translateY(-20%);
  transform: translateY(-20%);
  -webkit-transition: all 0.7s ease;
  -moz-transition: all 0.7s ease;
  -ms-transition: all 0.7s ease;
  -o-transition: all 0.7s ease;
  transition: all 0.7s ease;
}
.detail_box .box_inner h6 {
  font-size: 14px;
  font-weight: 700;
  color: #f90;
  padding-bottom: 40px;
  font-family: Roboto, sans-serif;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.detail_box .box_inner h4 {
  font-size: 24px;
  font-weight: 700;
  color: #222;
  padding-bottom: 25px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.detail_box .box_inner p {
  font-size: 14px;
  padding-bottom: 50px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.detail_box .box_inner a {
  font-size: 14px;
  font-weight: 700;
  color: #222;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.detail_box .box_inner a:hover {
  color: #f90;
}
.project-section2 {
  position: relative;
}
.project-section2 .owl-prev .fa {
  position: absolute;
  right: -30px;
  bottom: 100px;
  font-size: 24px !important;
  color: #f90 !important;
  font-weight: 700;
}
.project-section2 .owl-next {
  font-size: 24px;
  font-weight: 400;
  color: #777;
  position: absolute;
  right: -50px;
  bottom: 30px;
  font-family: Roboto, sans-serif;
  transform: rotate(90deg);
}
.project-section2 .owl-next:hover {
  color: #f90;
}
.second_img {
  padding-left: 40px;
}
.second_img img {
  width: 100%;
  height: 100%;
}
.project_text h6 {
  font-size: 18px;
  font-weight: 700;
  color: #222;
  position: absolute;
  right: 0;
  z-index: 9999;
  padding-top: 40px;
  padding-right: 120px;
}
.project_text h6 a {
  color: #f90;
  text-transform: uppercase;
}
.project_text h6 a:hover {
  text-decoration: underline;
}
.services_logo_div {
  width: 100px;
  height: 100px;
  border: 2px dashed #d00006;
  border-radius: 100px;
  margin-bottom: 10px;
  position: relative;
  margin: 0 auto;
}
.services_logo {
  width: 50%;
  position: absolute;
  left: 25px;
  top: 25%;
}
.services_logo_365 {
  width: 60%;
  position: absolute;
  left: 25px;
  top: 20%;
}
#btn_pro_services {
  background-color: unset;
  /* border: 2px solid maroon; */
  border-width: 3px;
  border-style: solid;
  border-image: linear-gradient(to top, red, rgba(0, 0, 0, 0)) 1 1%;
  color: #fff !important;
  font-size: 12px;
  border-radius: 0;
  max-width: 90px;
  margin: auto;
  font-family: "Roboto Condensed", sans-serif;
}
/* .green-outline-btn {
} */
.font-15 {
  font-size: 15px;
  color: #fff;
  margin-bottom: 14px;
  margin-top: 14px;
}
.green-outline-btn:hover {
  background-color: maroon !important;
  border: 2px solid maroon !important;
}
.bgdark {
  background-color: #111;
}
.backImage {
  background: url();
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  height: 200px;
  width: 100%;
}
.img-thumbnail {
  padding: 1rem;
  background-color: #000;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
.new-nav-setting a:hover {
  color: #000 !important;
}
.padin-Zero {
  padding: 0 !important;
}
.navbar-dark .setting-nav {
  padding: 0 !important;
}
.new-nav-setting a {
  font-weight: 700 !important;
}
.evs-listgroup p {
  /* border: solid 1px maroon;
  border-width: 3px; */
  border-style: solid;
  border-image: linear-gradient(to bottom, red, rgba(0, 0, 0, 0)) 1 20%;
  margin: 0 !important;
  padding: 2px 2px 2px 10px;
  color: #999 !important;
}
.card-blog .img-thumbnail {
  padding: 11px !important;
  background-color: #000;
  border: 1px solid #fff !important;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
.main-menu .navigation > li.dropdown > a:after {
  position: absolute !important;
  right: -11px !important;
  font-size: 14px !important;
  display: block !important;
  line-height: 30px !important;
  color: #fff !important;
  font-weight: 400 !important;
  z-index: 5 !important;
  content: "\f0d7" !important;
  font-family: FontAwesome !important;
}
.main-menu .navigation > li.new-set > a:after {
  right: -16px !important;
  color: #000 !important;
  top: 21px !important;
}
.hvr-bg-effect {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgb(0 0 0 / 0%);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  background: #e1e1e1;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.inline-list {
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
}
.inline-list li:nth-child(2n + 1) a {
  color: maroon;
  background-color: #111;
}
.inline-list li a {
  color: #000;
  background-color: #f4f4f4;
}
.inline-list li a {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 180px;
  font-size: 20px;
  text-align: left;
  padding: 15px;
  font-weight: 700;
  box-shadow: 20px 20px 10px 0 #333;
  -moz-box-shadow: 20px 20px 10px 0 #333;
  -webkit-box-shadow: 20px 20px 10px 0 #222;
  -webkit-transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  top: 0;
  left: 0;
  position: relative;
}
.inline-list li a span {
  position: absolute;
  right: 00px;
  bottom: 40px;
}
.arrow-effect {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgb(0 0 0 / 0%);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  padding-right: 2.2em;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
#services p {
  max-width: 70%;
  margin: 0 auto 30px;
}
#services {
  text-align: center;
  padding-top: 80px;
  width: calc(100% - 8%);
  margin: 0 4%;
}
#services h2 {
  font-size: 80px;
  font-weight: 200;
  line-height: 80px;
  font-style: normal;
  margin-top: 30px;
  margin-bottom: 30px;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
}
.inline-list li {
  display: inline-block;
  width: 100%;
  /* float: left; */
  position: relative;
  padding: 30px 15px 0 15px;
}
ol,
ul {
  margin-top: 0;
  margin-bottom: 10px;
}
.inline-list li:nth-child(2n + 1) a.hvr-bg-effect:before {
  background: #005dbd;
}
.inline-list li a.hvr-bg-effect:before {
  background: #fbca16;
}
.hvr-bg-effect:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.arrow-effect:before {
  content: "\f061";
  position: absolute;
  right: 1em;
  padding: 0 1px;
  font-family: FontAwesome;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.inline-list li a.hvr-bg-effect:before {
  background: #fbca16;
}
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.inline-list li a {
  color: #000;
  background-color: #f4f4f4;
}
.hvr-bg-effect:hover {
  background-color: maroon !important;
  color: #000 !important;
  transition: 0.4s ease !important;
  transform: scale(0.96) !important;
}
.inline-list li .extra-design {
  background-color: maroon !important;
  color: #000 !important;
}
.inline-list li .extra-design:hover {
  background-color: #111 !important;
  color: maroon !important;
}
.inline-list li .extra-design {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 180px;
  font-size: 20px;
  text-align: left;
  padding: 15px;
  font-weight: 700;
  box-shadow: 20px 20px 10px 0 #ce9178;
  -moz-box-shadow: 20px 20px 10px 0 #ce9178;
  -webkit-box-shadow: 20px 20px 10px 0 #500000;
  -webkit-transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  top: 0;
  left: 0;
  position: relative;
}
.center-image {
  text-align: center !important;
}
.card-body h2 {
  margin: 0 0 15px;
  font: 700 20px/24px Raleway, sans-serif;
}
.card-blog .center-image {
  /* background-color: maroon; */
  background: linear-gradient(
    90deg,
    rgba(121, 15, 15, 1) 0%,
    rgba(247, 2, 2, 1) 72%,
    rgba(247, 2, 2, 1) 100%,
    rgba(64, 63, 63, 1) 100%
  );
  padding: 12px;
  border-radius: 50%;
  width: fit-content;
  align-content: center !important;
  margin-left: 150px;
}
.COLOR1 {
  background-color: #000 !important;
}
.brder {
  border-right: 1px solid #fff;
}
.size {
  font-size: 30px;
}
.brdr-rigth {
  border-right: 0;
}
.line-clr {
  background-color: #fff;
  width: 40%;
}
.text-Clr {
  color: #ccc;
}
.bordrrr {
  border-right: 1px solid #555;
  border-bottom: 1px solid #555;
}
.secondborder {
  border-right: none !important;
}
.bgdark {
  background-color: #000;
}
.grydrkClr {
  background-color: #111 !important;
}
.chndRed {
  background: rgb(121, 15, 15);
  background: linear-gradient(
    90deg,
    rgba(121, 15, 15, 1) -6%,
    rgba(247, 2, 2, 1) 21%,
    rgba(247, 2, 2, 1) 19%,
    rgba(64, 63, 63, 1) 89%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.textdanger {
  color: maroon;
}
.textligth {
  color: #999;
  text-align: justify;
}
.botmbordr {
  border-bottom: none !important;
}
.ServiceDeliver .MediumHeading,
.ServiceHelp .MediumHeading {
  text-align: center;
}
.TEKHQS-color {
  color: maroon;
  background: linear-gradient(
    90deg,
    rgba(121, 15, 15, 1) 0%,
    rgba(247, 2, 2, 1) 72%,
    rgba(247, 2, 2, 1) 100%,
    rgba(64, 63, 63, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.MediumHeading {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
}
.HelpSection {
  padding-left: 0;
  padding-right: 0;
  margin-top: 85px;
}
.circles {
  padding-left: 0;
  padding-right: 0;
}
.outerCircle {
  height: 66px;
  width: 66px;
  border: 2px solid #777;
  border-radius: 100px;
  transition: 0.5s;
}
.HelpSection:hover .circleText {
  /* background-color: maroon; */
  background: linear-gradient(
    90deg,
    rgba(121, 15, 15, 1) 0%,
    rgba(247, 2, 2, 1) 72%,
    rgba(247, 2, 2, 1) 100%,
    rgba(64, 63, 63, 1) 100%
  );
  border: none !important;
  color: #000;
}
.HelpTextSection {
  padding-left: 0;
  padding-right: 20px;
}
.ServiceHelp .Heading {
  color: #555;
}
.BulletsText {
  font-size: 14px;
  color: #999;
  text-align: justify;
}
.lineS {
  width: 100%;
  height: 1px;
  background: #777;
  position: absolute;
  top: 34px;
  left: 15px;
}
.circleText {
  font-size: 20px;
  font-weight: 700;
  height: 48px;
  width: 48px;
  border: 1px solid #777;
  text-align: center;
  border-radius: 100%;
  padding-top: 9px;
  z-index: 1;
  position: relative;
  background: #ebebeb;
  display: block;
  margin: 0 auto;
  margin-top: 8px;
}
.Heading {
  margin-top: 22px;
  font-size: 20px;
  color: #000;
  font-weight: 700;
  text-align: left;
}
.bgImage {
  background-image: url(../images/background/11.jpg);
  width: 100%;
  height: auto;
  position: relative;
  padding: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.card-with-toggle-header .cwth-card-header {
  /* background-color: maroon; */
  background: linear-gradient(
    90deg,
    rgba(121, 15, 15, 1) 0%,
    rgba(247, 2, 2, 1) 78%,
    rgba(247, 2, 2, 1) 100%,
    rgba(64, 63, 63, 1) 100%
  );
  padding: 0.5rem 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.toggle-btn-box .toggle-btn {
  width: 24px;
  height: 13px;
}
.position-relative {
  position: relative !important;
}
.toggle-btn-box .toggle-btn .line1 {
  top: 0;
  width: 24px;
}
.toggle-btn-box .toggle-btn .line {
  height: 1px;
  background-color: #fff;
  position: absolute;
  left: 0;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}
.toggle-btn-box .toggle-btn .line2 {
  top: 6px;
  width: 24px;
}
.toggle-btn-box .toggle-btn .line3 {
  top: 12px;
  width: 18px;
}
.card-with-toggle-header .cwth-card-body {
  padding: 1rem;
}
.card-with-toggle-header .cwth-card-body h6 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-bottom: 0.5rem;
}
.card-with-toggle-header .cwth-card-body p:last-child {
  margin-bottom: 2rem;
  text-align: justify;
  color: #999;
}
.underlined {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #fff;
}
.ClrText {
  color: #999;
}
.medium-size-p {
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
}
.custom-card {
  height: 100%;
}
.why-choose-techverx .custom-card .custom-card-header {
  background-color: maroon;
  background: linear-gradient(
    90deg,
    rgba(121, 15, 15, 1) 0%,
    rgba(247, 2, 2, 1) 72%,
    rgba(247, 2, 2, 1) 100%,
    rgba(64, 63, 63, 1) 100%
  );
}
.custom-card .custom-card-header {
  padding: 0.5rem 1rem;
}
.why-choose-techverx .custom-card .custom-card-header .custom-card-title {
  color: #fff;
}
.why-choose-techverx .custom-card .custom-card-body {
  padding: 1.5rem 0.5rem;
}
.redClr {
  background: rgb(121, 15, 15);
  background: linear-gradient(
    90deg,
    rgba(121, 15, 15, 1) 0%,
    rgba(247, 2, 2, 1) 72%,
    rgba(247, 2, 2, 1) 100%,
    rgba(64, 63, 63, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.service-container {
  background: url(../images/bg-service-truck-3.jpeg);
  height: 22rem;
  position: relative;
  width: 100%;
  padding: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}
.portfolio-container {
  background: url(../images/background/8l.jpg);
  height: 22rem;
  position: relative;
  width: 100%;
  padding: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.counter-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: start;
  margin-right: -15px;
  margin-left: -15px;
}
.counter-box .single-coutnerup {
  width: 15%;
  padding: 1rem;
  text-align: left;
}
.counter-box .single-coutnerup span {
  font-size: 25px;
  font-weight: 700;
  font-family: "Proxima Bold";
}
.counter-box .single-coutnerup p {
  margin: 1rem 0 0;
  font-size: 14px;
}
.h2-size {
  font-size: 44px;
}
.bck-Clr {
  background-color: #000;
}
.medium-size-p {
  font-size: 19px;
  line-height: 1.6em;
  margin-bottom: 15px;
  color: #fff !important;
  font-family: "Proxima Regular";
}
.box-shadow-1 {
  background-color: #111;
}
.bk-Clr {
  background: #111;
}
.hidden-sm-down {
  /* background-image: url(../images//main-slider/main-video3.gif); */
  background-position: center;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 50vh;
}
.icon-wrapper {
  float: left;
  margin: 0 20px 10px 0;
}
.icon-wrapper {
  height: 182px;
}
.grayClr {
  background: #111;
}
.hoverEffect:hover h4 {
  /* color: maroon !important; */
  background: linear-gradient(
    90deg,
    rgba(121, 15, 15, 1) 0%,
    rgba(247, 2, 2, 1) 28%,
    rgba(247, 2, 2, 1) 100%,
    rgba(64, 63, 63, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: 0.5s ease;
  font-weight: 700;
}
.hoverEffect:hover p {
  color: #fff !important;
  transition: 0.5s ease;
}
.widget-content p {
  text-align: justify !important;
}
.about-widget p {
  text-align: justify !important;
}
.img-setting {
  margin: 20px !important;
}
.set-service {
  padding: 0 0 !important;
}
.up-set {
  margin-top: 7px !important;
}
.pull-right .padin-Zero > .new-nav-setting > li.dropdown > a:after {
  color: #000 !important;
}
.bgGrydark {
  background-color: #111 !important;
}
.text-2 {
  margin-bottom: 45px !important;
}
.align-items-center {
  align-items: center;
}
.cou-wite:hover {
  color: #fff !important;
}
.expert-inspan {
  /* color: maroon; */
  background: linear-gradient(
    90deg,
    rgba(121, 15, 15, 1) 0%,
    rgba(247, 2, 2, 1) 37%,
    rgba(247, 2, 2, 1) 100%,
    rgba(64, 63, 63, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.img-container {
  /* height: 35rem; */
  padding: 220px 0px;
  width: 100%;
  background-image: url(../images/truck-image-2.jpeg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  display: flex;
  align-items: center;
  /* background-color: red; */
  float: right;
}
.top-to-btm {
  bottom: 0;
  color: #fff;
  height: 2rem;
  position: relative;
  background-color: red;
  width: 2rem;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 24px;
}
.css-yw020d-MuiAccordionSummary-expandIconWrapper {
  color: #fff !important;
}

.activee {
  border-bottom: 0px solid;
  border-image-slice: 1;
  border-width: 3px;
  border-image: linear-gradient(to left, #f70202, #860e0e) 1 0;
}
.mini-active {
  border-bottom: 4px solid #000;
}
.main-active {
  color: #000 !important;
}
.link {
  color: #fff;
}
.styles_scroll-to-top__2A70v {
  background-color: #000 !important;
  width: 3rem !important;
  height: 3rem !important;
  border-radius: 0 !important;
  border: 2px solid maroon !important;
  box-shadow: 0 9px 25px 0 rgb(132 128 177 / 28%);
  z-index: inherit !important;
}
.styles_scroll-to-top__2A70v svg {
  width: 1rem !important;
  height: 1rem !important;
  fill: maroon;
}



.demo10{padding:30px 0}
.pricingTable10{text-align:center; border-radius:'12px !important'}
.pricingTable10 .pricingTable-header{padding:30px 0;background:#222;position:relative;transition:all .3s ease 0s}
.pricingTable10:hover .pricingTable-header{ background: linear-gradient(
  90deg,
  rgba(121, 15, 15, 1) 0%,
  rgba(247, 2, 2, 1) 72%,
  rgba(247, 2, 2, 1) 100%,
  rgba(64, 63, 63, 1) 100%
);}
.pricingTable10 .pricingTable-header:after,.pricingTable10 .pricingTable-header:before{content:"";width:16px;height:16px;border-radius:50%;border:1px solid #d9d9d8;position:absolute;bottom:12px}
.pricingTable10 .pricingTable-header:before{left:40px}
.pricingTable10 .pricingTable-header:after{right:40px}
.pricingTable10 .heading{font-size:20px;color:#fff;text-transform:uppercase;letter-spacing:2px;margin-top:0}
.pricingTable10 .price-value{position:relative;font-size:55px;font-weight:700;color:#800000;transition:all .3s ease 0s}
.pricingTable10:hover .price-value{color:#fff}
.pricingTable10 .currency{font-size:30px;font-weight:700;position:absolute;top:6px;left:-19px}
.pricingTable10 .month{font-size:16px;color:#fff;position:absolute;bottom:15px;text-transform:uppercase}
.pricingTable10 .pricing-content{padding-top:50px;background:#111;position:relative}
.pricingTable10 .pricing-content:after,.pricingTable10 .pricing-content:before{content:"";width:16px;height:16px;border-radius:50%;border:1px solid #7c7c7c;position:absolute;top:12px}
.pricingTable10 .pricing-content:before{left:40px}
.pricingTable10 .pricing-content:after{right:40px}
.pricingTable10 .pricing-content ul{padding:0 20px;margin:0;list-style:none}
.pricingTable10 .pricing-content ul:after,.pricingTable10 .pricing-content ul:before{content:"";width:8px;height:46px;border-radius:3px;background: linear-gradient(
  90deg,
  rgba(121, 15, 15, 1) 0%,
  rgba(247, 2, 2, 1) 72%,
  rgba(247, 2, 2, 1) 100%,
  rgba(64, 63, 63, 1) 100%
);position:absolute;top:-22px;z-index:1;box-shadow:0 0 5px #707070;transition:all .3s ease 0s}
.pricingTable10:hover .pricing-content ul:after,.pricingTable10:hover .pricing-content ul:before{background:  linear-gradient(
  90deg,
  rgba(121, 15, 15, 1) 0%,
  rgba(247, 2, 2, 1) 72%,
  rgba(247, 2, 2, 1) 100%,
  rgba(64, 63, 63, 1) 100%
);}
.pricingTable10 .pricing-content ul:before{left:44px}
.pricingTable10 .pricing-content ul:after{right:44px}
.pricingTable10 .pricing-content ul li{font-size:15px;font-weight:700;color:#fff;padding:10px 0;border-bottom:1px solid #333}
.pricingTable10 .pricing-content ul li:last-child{border-bottom:none}
.pricingTable10 .read{display:inline-block;font-size:16px;color:#fff;text-transform:uppercase; background: linear-gradient(
  90deg,
  rgba(121, 15, 15, 1) 0%,
  rgba(247, 2, 2, 1) 72%,
  rgba(247, 2, 2, 1) 100%,
  rgba(64, 63, 63, 1) 100%
);padding:8px 25px;margin:30px 0;transition:all .3s ease 0s}
.pricingTable10 .read:hover{text-decoration:none}
.pricingTable10:hover .read{ background: linear-gradient(
  90deg,
  rgba(121, 15, 15, 1) 0%,
  rgba(247, 2, 2, 1) 72%,
  rgba(247, 2, 2, 1) 100%,
  rgba(64, 63, 63, 1) 100%
);}
@media screen and (max-width:990px){.pricingTable10{margin-bottom:25px}
}
/* Credit to https://bootsnipp.com/snippets/92erW */